import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import maintanence from "../../assets/images/coming-soon.svg"
import { withTranslation } from "react-i18next"
import Moment from 'moment';
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import { v4 as uuid } from "uuid"
import imageCompression from 'browser-image-compression';


// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
import "firebase/functions";

import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import ReactSelect from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody, Alert } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getStaff,
  addNewStaff,
  updateStaff,
  deleteStaff
} from "store/actions"


import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";

class Staff extends Component {
  constructor(props, firebaseConfig) {
    super(props)
    this.state = {
      isAdmin: false,
      user_email: "",
      ibadl: false,
      loading: true,
      submitLoading: false,
      isShow: false,
      changed: false,
      gender: "",
      viewmodal: false,
      modal: false,
      isDelete: false,
      isInvite: false,
      isDinvite: false,
      isCinvite: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      kra: "",
      vc: "",
      selectedGroup: "Other",
      staffs: [],
      staff: [],
      date_birth: new Date(),
      StaffColumns: [
        /*  {
           dataField: "code",
           text: "#",
           sort: true,
           formatter: (cellContent, row) => (
             <Link to="#" className="text-body fw-bold">
               MZS{row.code}
             </Link>
           ),
         }, */
        {
          dataField: "first_name",
          text: this.props.t("First name"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.first_name}
            </Link>
          ),
        },
        {
          dataField: "last_name",
          text: this.props.t("Last name"),
          sort: true,
        },
        {
          dataField: "email",
          text: this.props.t("E-mail"),
          sort: true,
          /* formatter: (cellContent, row) => (
            this.handleValidDate(row.orderdate)
          ), */
        },
        {
          dataField: "mobile",
          text: this.props.t("Phone Number"),
          sort: true,
        },
        {
          dataField: "designation",
          isDummyField: true,
          text: this.props.t("Designation"),
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <i className={
                row.designation === "Manager" ?
                  "fas fa-briefcase me-1"
                  : row.designation === "Secretary" ?
                    "fas fa-desktop me-1"
                    : (row.designation === "Driver") ? "fas fa-car me-1" : (row.designation === "Other") ? "fas fa-user me-1" : null
              } />{" "}
              {this.props.t(row.designation)}
            </>
          ),
        },

        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("Invite Users"),
          sort: true,
          formatter: (cellContent, staff) => (
            <>{staff.status === "none" ? <Button
              type="button"
              color="success"
              className="btn-sm btn-rounded"
              onClick={() => this.setState({ isInvite: true, kra: staff })}
            >
              {this.props.t("Invite")}
            </Button> : null}
              {staff.status === "user" ? <Button
                type="button"
                color="danger"
                className="btn-sm btn-rounded"
                onClick={() => this.setState({ isDinvite: true, kra: staff })}
              >
                {this.props.t("Admin")}
              </Button> : null}
              {staff.status === "pending" ? <Button
                type="button"
                color="secondary"
                className="btn-sm btn-rounded"
                onClick={() => this.setState({ isCinvite: true, kra: staff })}
              >
                {this.props.t("Pending")}
              </Button> : null}
            </>

          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Action"),
          formatter: (cellContent, staff) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-primary">
                  <i className="mdi mdi-eye-outline font-size-18" id="edittooltip" onClick={() => this.handleViewClick(staff)} />
                </Link>
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleStaffClick(staff)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: staff })} />
                </Link>
              </div>
            </>
          ),
        },
      ],
      selectedFiles: []
    }

    this.handleStaffClick = this.handleStaffClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidStaffSubmit = this.handleValidStaffSubmit.bind(this)
    this.handleStaffClicks = this.handleStaffClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.handleSelectGroup = this.handleSelectGroup.bind(this)
    this.handleGenderChange = this.handleGenderChange.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)

    this.toggleInvite = this.toggleInvite.bind(this)
    this.toggleDinvite = this.toggleDinvite.bind(this)
    this.toggleCinvite = this.toggleCinvite.bind(this)
  }
  printStaff = () => {
    window.print()
  }
  handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  handleGenderChange = e => {
    const { name, value } = e.target;

    this.setState({
      gender: value
    });

  };

  toLowerCase1(str) {
    return str.toLowerCase();
  }
  handleSelectGroup = selectedGroup => {

    this.setState({ selectedGroup: selectedGroup })
    this.setState({ changed: true })
  }
  componentDidMount() {
    const { staffs, onGetStaff } = this.state
    if (staffs && !staffs.length) {
      //onGetStaff()
      this.getStaff()

    }
    this.getUser()
    this.setState({ staffs })

  }
  getUser = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers();
    }

    this.unsubscribeUsers = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        //console.log(user)
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {

            if (userDoc.data().isAdmin) {
              this.setState({
                isAdmin: true
              })

            }
            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");

          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });

  }
  addNewTimeline = async (timeline) => {
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      //const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data:timeline.data,
        user: "Admin"
      });

      //console.log("Timeline  written!");
    } catch (error) {
      console.error("Error adding timeline:", error);
    }
  }
  getStaff = async () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        //console.log(user.email)

        user = firebase.auth().currentUser.uid

        ////console.log("dkhel " + user)
        const collection = firebase.firestore().collection("users")


        this.setState({ loading: true }, () => {
          collection.doc(user).collection("staffs")
            .onSnapshot((doc) => {
              let kra = []
              doc.docs.forEach(e => {
                kra.push(e.data())
              });
              this.setState({
                loading: false,
                staffs: kra,
                user_email: user.email,
                user_id: user
              })
            }

            );
        });
        localStorage.setItem("authUser", JSON.stringify(user))
      } else {
        ////console.log("not user")
        localStorage.removeItem("authUser")
      }
    })

  }

  // eslint-disable-next-line no-unused-vars
  /* componentDidUpdate(prevProps, prevState, snapshot) {
    const { staffs } = this.state

    if (!isEmpty(staffs) && size(prevProps.staffs) !== size(staffs)) {
      //this.setState({ staffs: {}, isEdit: false })

    }

  } */
  toggleInvite() {
    this.setState(prevState => ({
      isInvite: !prevState.isInvite,
    }))
  }
  toggleDinvite() {
    this.setState(prevState => ({
      isDinvite: !prevState.isDinvite,
    }))
  }
  toggleCinvite() {
    this.setState(prevState => ({
      isCinvite: !prevState.isCinvite,
    }))
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      ibadl: false
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }
  toggleNoData() {
    this.setState(prevState => ({
      no_data: !prevState.no_data,
    }))
  }

  handleStaffClicks = () => {
    this.setState({ staff: [], isEdit: false, isShow: false, selectedGroup: null })

    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { staffs } = this.props
    this.setState({
      staffs: staffs.filter(staff =>
        Object.keys(staff).some(
          key =>
            typeof staff[key] === "string" &&
            staff[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }

  InviteUser = async (staff) => {
    try {
      this.setState({ submitLoading: true });

      const collection = firebase.firestore().collection("invitations");
      const staffCollection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs").doc(staff.id);

      const timeline = {
        statusTitle: "Admin invitation sent",
        iconClass: "bx bx-user-check  h2 text-success",
        description: "An invitation sent to ",
        data: staff.first_name+" "+staff.last_name,
        time: new Date().getTime(),
        user: "Admin"
      };

      //console.log(firebase.auth().currentUser.email);

      const iid = uuid();
      const newDocRef = collection.doc();
      await newDocRef.set({
        id: newDocRef.id,
        code: iid,
        from: firebase.auth().currentUser.email,
        account_id: firebase.auth().currentUser.uid,
        first_name: staff.first_name,
        last_name: staff.last_name,
        staff_id: staff.id,
        email: staff.email,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        status: "created"
      });

      //console.log("Staff  written!");
      toast.success(this.props.t('Invitation sent '))

      await staffCollection.update({
        status: "pending",
        invitationId: newDocRef.id
      });

      this.addNewTimeline(timeline);
      this.setState({ submitLoading: false });
      //this.toggleInvite();
    } catch (error) {
      console.error("Error sending Invitation:", error);
      toast.error(this.props.t("Error Sending Invitation"))
      this.setState({ submitLoading: false });
    }
  }
  DinviteUser = async (staff) => {
    try {
      // Delete the user
      this.setState({ submitLoading: true })
      const staffCollection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs").doc(staff.id);

      const deleteUserCallable = firebase.app().functions('europe-west1').httpsCallable('deleteUser');
      await deleteUserCallable({ staff });
      //console.log('User deleted .');
      toast.success(this.props.t('Admin Privilige deleted '))
      const timeline = {
        statusTitle: "Admin Privilige deleted",
        iconClass: "bx bx-user-x  h2 text-danger",
        description: "An invitation deleted ",
        data: staff.first_name+" "+staff.last_name,
        time: new Date().getTime(),
        user: "Admin"
      };
      await staffCollection.update({
        status: "none",
        invitationId: null,
        isUser: false,
        userId: ""
      });
      this.addNewTimeline(timeline);
      this.setState({ submitLoading: false });
      //this.toggleDinvite();
    } catch (error) {
      this.setState({ submitLoading: false });
      console.error('Error deleting user:', error.message);
      toast.error(this.props.t('Error Deleting Admin Privilige'))
      //this.toggleDinvite();
    }
  }

  CinviteUser = async (staff) => {
    try {
      this.setState({ submitLoading: true });

      const collection = firebase.firestore().collection("invitations");
      const staffCollection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs").doc(staff.id);

      const timeline = {
        statusTitle: "Admin invitation cancelled",
        iconClass: "bx bx-user-x  h2 text-warning",
        description: "An invitation cancelled ",
        data: staff.first_name+" "+staff.last_name,
        time: new Date().getTime(),
        user: "Admin"
      };

      //console.log(firebase.auth().currentUser.email);

      const idoc = collection.doc(staff.invitationId);
      await idoc.update({
        status: "cancelled"
      });

      //console.log("Invitation  cancelled!");
      toast.success(this.props.t('Invitation cancelled '));

      await staffCollection.update({
        status: "none",
        invitationId: null
      });

      this.addNewTimeline(timeline);
      this.setState({ submitLoading: false });
      //this.toggleCinvite();
    } catch (error) {
      console.error("Error cancelling Invitation:", error);
      toast.error(this.props.t("Error Cancelling Invitation"));
      this.setState({ submitLoading: false });
      //this.toggleCinvite();
    }
  }

  /* Insert,Update Delete data */
  handleInviteUser = (staff) => {
    this.InviteUser(staff)
    this.toggleInvite()
  }
  handleDinviteUser = (staff) => {
    this.DinviteUser(staff)
    this.toggleDinvite()
  }
  handleCinviteUser = (staff) => {

    this.CinviteUser(staff)
    this.toggleCinvite()
  }

  handleDeleteStaff = (staff) => {
    ////console.log("delete inside index " + this.state.staffs.id_file)

    // const { onDeleteStaff } = this.props
    /* if (staff['code'] === '') {
       //onDeleteStaff(order)
     } else { */
    //onDeleteStaff(staff)
    this.deleteStaff(staff)
    //toast.success(this.props.t('Staff member infos deleted '))
    this.toggleDelete()
    //}

  }



  handleViewClick = arg => {
    const stf = arg

    this.setState({ selectedGroup: { label: stf.designation, value: stf.designation } })
    this.setState({
      staff: {
        //code: staff.code,
        first_name: stf.first_name,
        last_name: stf.last_name,
        email: stf.email,
        mobile: stf.mobile,
        designation: stf.designation,
        address: stf.address,
        city: stf.city,
        state: stf.state,
        gender: stf.gender,
        date_birth: stf.date_birth,
        id_number: stf.id_number,
      },
      gender: stf.gender,
      isShow: true,
      isEdit: false
    })

    //this.toggleIsShow()
    this.toggle()
    // //console.log(this.state.isShow)
    //this.toggleViewModal()
  }


  handleStaffClick = arg => {
    const stf = arg

    /* this.getDownloadLink1(stf.id_file)
    this.getDownloadLink(stf.id_file1) */
    this.setState({ selectedGroup: { label: stf.designation, value: stf.designation } })
    this.setState({
      staff: {
        id: stf.id,
        first_name: stf.first_name,
        last_name: stf.last_name,
        email: stf.email,
        mobile: stf.mobile,
        designation: stf.designation,
        address: stf.address,
        city: stf.city,
        state: stf.state,
        gender: stf.gender,
        date_birth: stf.date_birth,
        id_number: stf.id_number,
      },
      isEdit: true,
      isShow: false,
      gender: stf.gender,

    })

    this.toggle()
  }

  /**
   * Handling submit Order on Order form
   */
  handleValidStaffSubmit = (e, values) => {
    const { onAddNewStaff, onUpdateStaff } = this.props
    const { isEdit, staffs, selectedStaff } = this.state
    const cds = []
    var kr = false
    var em = 0, mo = 0, id = 0
    var emA = 0, moA = 0, idA = 0


    const foundem = staffs.filter(element => element.email === values.email.trim() && this.state.staff.id !== element.id);
    em = foundem.length
    //console.log(foundem)
    const foundmo = staffs.filter(element => element.mobile === values.mobile.trim() && this.state.staff.id !== element.id);
    mo = foundmo.length
    const foundid = staffs.filter(element => element.id_number === values.id_number.trim() && this.state.staff.id !== element.id);
    id = foundid.length

    const foundemA = staffs.filter(element => element.email === values.email.trim());
    emA = foundemA.length
    const foundmoA = staffs.filter(element => element.mobile === values.mobile.trim());
    moA = foundmoA.length
    const foundidA = staffs.filter(element => element.id_number === values.id_number.trim());
    idA = foundidA.length





    if (isEdit) {
      //des=this.state.selectedGroup.defaultInputValue

      const updateStaff = {
        id: this.state.staff.id,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile: values.mobile,
        designation: values.designation,
        address: values.address,
        city: values.city,
        state: values.state,
        gender: this.state.gender,
        date_birth: values.date_birth,
        id_number: values.id_number,

      }

      if (!this.state.ibadl && this.state.staff.first_name.trim() == updateStaff.first_name.trim()
        && this.state.staff.last_name.trim() == updateStaff.last_name.trim()
        && this.state.staff.email.trim() == updateStaff.email.trim()
        && this.state.staff.mobile.trim() == updateStaff.mobile.trim()
        && this.state.staff.designation.trim() == updateStaff.designation.trim()
        && this.state.staff.address.trim() == updateStaff.address.trim()
        && this.state.staff.city.trim() == updateStaff.city.trim()
        && this.state.staff.state.trim() == updateStaff.state.trim()
        && this.state.staff.gender.trim() == updateStaff.gender.trim()
        && this.state.staff.date_birth.trim() == updateStaff.date_birth.trim()
        && this.state.staff.id_number.trim() == updateStaff.id_number.trim()) {
        this.setState({ submitLoading: false })
        this.toggle()

      } else {
        this.updateStaff(updateStaff, em, mo, id)
      }
      // update Order
      //onUpdateStaff(updateStaff)


      // toast.success(this.props.t('Staff member updated '))
    } else {

      const newStaff = {
        //code: cd,
        first_name: values["first_name"],
        last_name: values["last_name"],
        email: values["email"],
        mobile: values["mobile"],
        designation: values["designation"],
        address: values["address"],
        city: values['city'],
        state: values["state"],
        gender: this.state.gender,
        date_birth: values["date_birth"],
        id_number: values["id_number"],
        isUser: false,
        status: "none"
        //id_file:images.fullPath
      }
      ////console.log(des)
      // save new Order
      // onAddNewStaff(newStaff)
      this.setState({ staff: newStaff })
      this.addNewStaff(newStaff, emA, moA, idA)
      //toast.success(this.props.t('Staff member Added '))
    }
    this.setState({ selectedStaff: null })
    // this.toggle()
  }
  deleteStaff = (staff) => {
    ////console.log(staff)
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs")
    const timeline = {
      statusTitle: "Staff member deleted",
      iconClass: "bx bx-group  h2 text-danger",
      description: "Staff member information has been deleted for " ,
      data: staff.first_name+" "+staff.last_name,
      time: new Date().getTime(),
      user: "Admin"
    }
    collection.doc(staff.id).delete().then(() => {

      //console.log("Staff member  deleted!");
      toast.success(this.props.t("Staff member  deleted!"))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Staff member has been deleted."),
      })
    }).catch((error) => {
      console.error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"));


    });
  }

  updateStaff = async (staff, em, mo, id) => {

    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs")
    const timeline = {
      statusTitle: "Staff member updated",
      iconClass: "bx bx-group h2 text-warning",
      description: "Staff member information has been updated for ",
      data: staff.first_name+" "+staff.last_name,
      time: new Date().getTime(),
      user: "Admin"
    }
    //console.log(firebase.auth().currentUser.email)
    const account_email = firebase.auth().currentUser.email
    if (account_email !== staff.email && em == 0 && mo == 0 && id == 0) {

      collection.doc(staff.id).update({

        first_name: staff.first_name,
        last_name: staff.last_name,
        email: staff.email,
        mobile: staff.mobile,
        designation: staff.designation,
        address: staff.address,
        city: staff.city,
        state: staff.state,
        gender: staff.gender,
        date_birth: staff.date_birth,
        id_number: staff.id_number,

      }).then(() => {
        //console.log("Document  Updated!");
        toast.success(this.props.t("Staff  Updated!"))

        this.addNewTimeline(timeline)
        this.setState({ submitLoading: false })
        this.toggle()
      }).catch((error) => {
        console.error("Error updating document: ", error);
        toast.error(this.props.t("Error updating document"))
        this.setState({ submitLoading: false })
      });
    } else {
      if (account_email === staff.email) {
        console.error("You cannot use this email !");
        toast.error(this.props.t("You cannot use this email !"))
        this.setState({ submitLoading: false })
      }
      if (em != 0) {
        console.error("Email already exist ");
        toast.error(this.props.t("Email already exist"))
        this.setState({ submitLoading: false })
      }
      if (mo != 0) {
        console.error("Mobile number already exist ");
        toast.error(this.props.t("Mobile number already exist"))
        this.setState({ submitLoading: false })
      }
      if (id != 0) {
        console.error("ID number already exist ");
        toast.error(this.props.t("ID number already exist"))
        this.setState({ submitLoading: false })
      }
    }

  }

  addNewStaff = async (staff, em, mo, id) => {
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("staffs")

    this.setState({ submitLoading: true })
    const iid = uuid()
    const timeline = {
      statusTitle: "Staff member added",
      iconClass: "bx bx-group h2 text-success",
      description: "Staff member information has been added ",
      data: staff.first_name+" "+staff.last_name,
      time: new Date().getTime(),
      user: "Admin"
    }
    var newDoc = collection.doc();
    //console.log(firebase.auth().currentUser.email)
    const account_email = firebase.auth().currentUser.email
    if (account_email !== staff.email && em == 0 && mo == 0 && id == 0) {
      newDoc.set(
        {
          id: newDoc.id,
          first_name: staff.first_name,
          last_name: staff.last_name,
          email: staff.email,
          mobile: staff.mobile,
          designation: staff.designation,
          address: staff.address,
          city: staff.city,
          state: staff.state,
          gender: staff.gender,
          date_birth: staff.date_birth,
          id_number: staff.id_number,
          isUser: staff.isUser,
          status: staff.status

        }).then(() => {
          //console.log("stf  written!");
          toast.success(this.props.t('Staff member added '))

          this.addNewTimeline(timeline)
          this.setState({ submitLoading: false })
          this.toggle()
        })
        .catch((error) => {
          console.error("Error Adding staff member: ", error);
          toast.error(this.props.t("Error Adding staff member infos"))
          this.setState({ submitLoading: false })
        });
    } else {
      if (account_email === staff.email) {
        console.error("You cannot use this email !");
        toast.error(this.props.t("You cannot use this email !"))
        this.setState({ submitLoading: false })
      }
      if (em != 0) {
        console.error("Email already exist ");
        toast.error(this.props.t("Email already exist"))
        this.setState({ submitLoading: false })
      }
      if (mo != 0) {
        console.error("Mobile number already exist ");
        toast.error(this.props.t("Mobile number already exist"))
        this.setState({ submitLoading: false })
      }
      if (id != 0) {
        console.error("ID number already exist ");
        toast.error(this.props.t("ID number already exist"))
        this.setState({ submitLoading: false })
      }
    }


  }


  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }



  render() {

    const emptyDataMessage = () => {
      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding your staff members informations by clicking")}<h5 className="text-primary"><Link onClick={this.handleStaffClicks} >{this.props.t("New Staff member")}</Link></h5>
                </p>


              </div>
            }

          </Col>
        </Row>
      </Container>


        ;
    }
    const { staffs, isAdmin } = this.state
    const data = staffs;
    // //console.log(data)
    let dataxcl = []
    data.map(function (item) {
      delete item.staff;
      dataxcl.push({
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        mobile: item.mobile,
        designation: item.designation,
        id_number: item.id_number,
        date_birth: item.date_birth,
        gender: item.gender,
        address: item.address,
        city: item.city,
        state: item.state
      })
    });
    /*  let newArray = data.map(function (item) {
       delete item.staff;
 
       return item;
     }); */

    ////console.log(dataxcl)


    ////console.log("staffs" + staffs.length)

    const { gender } = this.state
    const { selectedGroup } = this.state
    const { SearchBar } = Search
    const { date_birth } = this.state;
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc, kra } = this.state
    const optionGroup =
      [
        { label: "", value: "" },
        { label: this.props.t("Manager"), value: "Manager" },
        { label: this.props.t("Secretary"), value: "Secretary" },
        { label: this.props.t("Driver"), value: "Driver" },
        { label: this.props.t("Other"), value: "Other" }
      ]


    function exportPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Staffs Infos";
      const headers = [["Name", "Email", "Address", "Mobile", "Gender", "ID number", "Designation"]];

      const dt = data.map(elt => [elt.first_name + " " + elt.last_name, elt.email, elt.address + " \n" + elt.city, elt.mobile, elt.gender, elt.id_number, elt.designation]);

      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Staff.pdf")


    }
    function printPDF() {
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);
      const title = "Staffs Infos";
      const headers = [["Name", "Email", "Address", "Mobile", "Gender", "ID number", "Designation"]];

      const dt = data.map(elt => [elt.first_name + " " + elt.last_name, elt.email, elt.address + " \n" + elt.city, elt.mobile, elt.gender, elt.id_number, elt.designation]);
      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }



    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: staffs.length, // replace later with size(Order),
      custom: true,
    }

    const defaultSorted = [{
      dataField: 'first_name',
      order: 'asc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };

    function ExportToExcel() {

      exportFromJSON({ data: dataxcl, fileName: 'Staffs', exportType: exportFromJSON.types.xls })
    }


    ////console.log(this.state.staffs.gender)
    /*  var myCurrentDate=new Date();
     var d=new Date(myCurrentDate);
         d.setFullYear(d.getFullYear() - 18);
     var minDate=d.getFullYear()+"-01"
         //console.log(minDate) */
    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Staff | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Extras")} breadcrumbItem={this.props.t("Staff")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}

            <Row>
              <Col xs="12">
                {isAdmin ?
                  <Card>
                    <CardBody>
                      <PaginationProvider
                        pagination={paginationFactory()}
                        keyField='id'
                        columns={(this.state.StaffColumns || [])}
                        data={(staffs || [])}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            data={staffs}
                            columns={(this.state.StaffColumns || [])}
                            bootstrap4
                            search

                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2" >
                                  <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>

                                  <Col sm="8" >

                                    <div className="text-sm-end" >
                                      <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded mb-2 me-2"
                                        onClick={this.handleStaffClicks}
                                      >
                                        <i className="mdi mdi-plus me-1" />{" "}
                                        {this.props.t("New Staff member")}
                                      </Button>

                                      <Link to="#"

                                      >

                                        {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                        <td>
                                          <UncontrolledDropdown>
                                            <DropdownToggle href="#" className="card-drop" tag="a">
                                              <i className="mdi mdi-dots-vertical font-size-18" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-end">
                                              <DropdownItem href="#" onClick={() => this.state.staffs.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                                <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                                {this.props.t("Export PDF")}

                                              </DropdownItem>
                                              <DropdownItem href="#" onClick={() => this.state.staffs.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                                <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                                {this.props.t("Export Excel")}
                                              </DropdownItem>
                                              <DropdownItem href="#" onClick={() => this.state.staffs.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                                <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                                {this.props.t("Print")}
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </UncontrolledDropdown>

                                        </td>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                                <div className="table-responsive">
                                  <BootstrapTable

                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    responsive
                                    defaultSorted={defaultSorted}
                                    bordered={false}
                                    striped={true}
                                    noDataIndication={emptyDataMessage}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                  />
                                  <Modal
                                    isOpen={this.state.modal}
                                    className={this.props.className}
                                  >
                                    <ModalHeader toggle={this.toggle} tag="h4">
                                      {!!this.state.isEdit ? this.props.t("Edit Staff Member") : null}
                                      {!!this.state.isShow ? this.props.t("Staff Member Infos") : null}
                                      {!this.state.isEdit && !this.state.isShow ? this.props.t("Add Staff Member") : null}
                                    </ModalHeader>
                                    <ModalBody>
                                      {this.state.submitLoading ?
                                        <Col>
                                          <div>
                                            <div >
                                              <div className="spinner-chase">
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                                <div className="chase-dot"></div>
                                              </div>
                                            </div>
                                          </div>
                                          <br />
                                          <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                        </Col>

                                        :

                                        <AvForm
                                          onValidSubmit={
                                            this.handleValidStaffSubmit
                                          }
                                        // disabled={this.state.isShow ? true : false}
                                        >

                                          <Row>

                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom01">
                                                  {this.props.t("First name")}
                                                </Label>
                                                <AvField
                                                  name="first_name"
                                                  placeholder={this.props.t("First name")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter First Name")}
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                  id="validationCustom01"
                                                  value={this.state.staff.first_name || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom02">
                                                  {this.props.t("Last name")}
                                                </Label>
                                                <AvField
                                                  name="last_name"
                                                  placeholder={this.props.t("Last name")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter Last name")}
                                                  className="form-control"
                                                  validate={{ required: { value: true } }}
                                                  id="validationCustom02"
                                                  value={this.state.staff.last_name || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom03">
                                                  {this.props.t("E-mail")}
                                                </Label>
                                                <AvField
                                                  name="email"
                                                  placeholder={this.props.t("E-mail")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter a valid E-mail")}
                                                  className="form-control"
                                                  validate={{
                                                    required: { value: true },
                                                    email: { value: true },
                                                  }}
                                                  id="validationCustom03"
                                                  value={this.state.staff.email || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />

                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-4">
                                                <Label> {this.props.t("Date of birth")}</Label>
                                                <AvField
                                                  name="date_birth"
                                                  placeholder={this.props.t("Date of birth")}
                                                  type="date"
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={Moment(this.state.staff.date_birth).format('YYYY-MM-DD') || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>

                                          </Row>
                                          <Row>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label htmlFor="validationCustom04">
                                                  {this.props.t("Phone Number")}
                                                </Label>
                                                <AvField
                                                  name="mobile"
                                                  placeholder={this.props.t("Phone Number")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter a valid phone number")}
                                                  className="form-control"

                                                  validate={{
                                                    required: { value: true },
                                                    pattern: {
                                                      value: '^(0|\\+)\\d{9,14}$',
                                                      errorMessage: this.props.t("Enter a valid phone number")
                                                    },
                                                  }}
                                                  id="validationCustom04"
                                                  value={this.state.staff.mobile || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6 ">
                                              <FormGroup className="mb-3">
                                                <Label className="d-block mb-3">{this.props.t("Gender")}</Label>
                                                <div className="form-check form-check-inline">

                                                  <input
                                                    id="windows"
                                                    value="Male"
                                                    name="gender"
                                                    type="radio"
                                                    className="form-check-input"
                                                    defaultChecked={this.state.staff.gender == "Male" ? true : false}
                                                    onChange={this.handleGenderChange}
                                                    disabled={this.state.isShow ? true : false}
                                                  />
                                                  <Label
                                                    className="form-check-label"
                                                    htmlFor="customRadioInline1"
                                                  >
                                                    {this.props.t("Male")}
                                                  </Label>
                                                </div>
                                                &nbsp;
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    id="mac"
                                                    value="Female"
                                                    name="gender"
                                                    type="radio"
                                                    className="form-check-input"
                                                    defaultChecked={this.state.staff.gender == "Female" ? true : false}
                                                    onChange={this.handleGenderChange}
                                                    disabled={this.state.isShow ? true : false}
                                                  />
                                                  <Label
                                                    className="form-check-label"
                                                    htmlFor="customRadioInline1"
                                                  >
                                                    {this.props.t("Female")}
                                                  </Label>
                                                </div>
                                              </FormGroup>

                                            </Col>

                                          </Row>

                                          <Row>
                                            <Col md="12">
                                              <FormGroup className="mb-3">
                                                <AvField
                                                  name="address"
                                                  label={this.props.t("Address")}
                                                  type="textarea"
                                                  errorMessage={this.props.t("Invalid Address")}
                                                  rows="1"
                                                  placeholder={this.props.t("Address")}
                                                  value={this.state.staff.address || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>

                                          </Row>

                                          <Row>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <AvField
                                                  name="city"
                                                  label={this.props.t("City")}
                                                  type="text"
                                                  errorMessage={this.props.t("Invalid City")}
                                                  placeholder={this.props.t("City")}
                                                  value={this.state.staff.city || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <AvField
                                                  name="state"
                                                  label={this.props.t("Region")}
                                                  type="text"
                                                 /*  errorMessage={this.props.t("Invalid State")} */
                                                  placeholder={this.props.t("region")}
                                                  value={this.state.staff.state || ""}
                                                  disabled={this.state.isShow ? true : false}
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>

                                            <Col md="6">
                                              <FormGroup className="mb-3">

                                                <Label>{this.props.t("Designation")}</Label>
                                                <AvField
                                                  name="designation"
                                                  type="select"
                                                  placeholder={this.state.staff.designation}
                                                  //onChange={this.handleSelectGroup}
                                                  //options={optionGroup}
                                                  value={this.state.staff.designation}
                                                  isDisabled={this.state.isShow ? true : false}
                                                  errorMessage={this.props.t("Select a designation")}
                                                  className="form-control"

                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  disabled={this.state.isShow ? true : false}
                                                >

                                                  {optionGroup.map((e, key) => {
                                                    if (key == 0) {
                                                      return <option key={key} value="" disabled>{this.props.t("designation")}</option>;
                                                    } else {
                                                      return <option key={key} value={e.value}>{e.label}</option>;
                                                    }

                                                  })}
                                                </AvField>



                                              </FormGroup>
                                            </Col>
                                            <Col md="6">
                                              <FormGroup className="mb-3">
                                                <Label >
                                                  {this.props.t("Client ID number")}
                                                </Label>
                                                <AvField
                                                  name="id_number"
                                                  placeholder={this.props.t("Client ID number")}
                                                  type="text"
                                                  errorMessage={this.props.t("Enter Client ID number")}

                                                  disabled={this.state.isShow ? true : false}
                                                  validate={{
                                                    required: { value: true },
                                                  }}
                                                  value={this.state.staff.id_number || ""}
                                                />
                                              </FormGroup>
                                            </Col>
                                          </Row>


                                          {this.state.isShow ? null :
                                            <Row>
                                              <Col>
                                                <div className="text-end">

                                                  <button
                                                    type="submit"
                                                    className="btn btn-success save-user"
                                                  >
                                                    {this.props.t("Save")}
                                                  </button>
                                                </div>
                                              </Col>
                                            </Row>
                                          }

                                        </AvForm>
                                      }
                                    </ModalBody>
                                  </Modal>

                                  {this.state.isInvite ? (
                                    <SweetAlert
                                      title={this.props.t("Send invitation to")}
                                      showCancel
                                      confirmButtonText={this.props.t("Yes, invite!")}
                                      confirmBtnBsStyle="success"
                                      cancelBtnBsStyle="danger"
                                      onConfirm={() =>
                                        this.handleInviteUser(kra)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          isInvite: false,
                                        })
                                      }
                                    >
                                      {" " + kra.email + " ? "}
                                    </SweetAlert>
                                  ) : null}

                                  {this.state.isCinvite ? (
                                    <SweetAlert
                                      title={this.props.t("Cancel invitation sent to")}
                                      showCancel
                                      confirmButtonText={this.props.t("Yes, Cancel!")}
                                      confirmBtnBsStyle="success"
                                      cancelBtnBsStyle="danger"
                                      onConfirm={() =>
                                        this.handleCinviteUser(kra)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          isCinvite: false,
                                        })
                                      }
                                    >
                                      {" " + kra.email + " ? "}
                                    </SweetAlert>
                                  ) : null}


                                  {this.state.isDinvite ? (
                                    <SweetAlert
                                      title={this.props.t("Delete Admin privilige for")}
                                      showCancel
                                      confirmButtonText={this.props.t("Yes, Cancel!")}
                                      confirmBtnBsStyle="success"
                                      cancelBtnBsStyle="danger"
                                      onConfirm={() =>
                                        this.handleDinviteUser(kra)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          isDinvite: false,
                                        })
                                      }
                                    >
                                      {" " + kra.email + " ? "}
                                    </SweetAlert>
                                  ) : null}
                                  {this.state.isDelete ? (
                                    <SweetAlert
                                      title={this.props.t("Are you sure?")}
                                      warning
                                      showCancel
                                      confirmButtonText={this.props.t("Yes, delete it!")}
                                      confirmBtnBsStyle="success"
                                      cancelBtnBsStyle="danger"
                                      onConfirm={() =>
                                        this.handleDeleteStaff(vc)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          isDelete: false,
                                        })
                                      }
                                    >
                                      {this.props.t("You won't be able to revert this!")}
                                    </SweetAlert>
                                  ) : null}

                                  {this.state.no_data ? (
                                    <SweetAlert
                                      title={this.props.t("No data!")}
                                      warning
                                      onConfirm={() => this.setState({ no_data: false })}
                                    >
                                      {this.props.t("There is no data to export")}
                                    </SweetAlert>
                                  ) : null}

                                </div>
                                {/* <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div> */}
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                  : null}

              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Staff.propTypes = {
  staffs: PropTypes.array,
  onGetStaff: PropTypes.func,
  onAddNewStaff: PropTypes.func,
  onDeleteStaff: PropTypes.func,
  onUpdateStaff: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  staffs: state.staff.staffs
})

const mapDispatchToProps = dispatch => ({
  onGetStaff: () => dispatch(getStaff()),
  onAddNewStaff: staff => dispatch(addNewStaff(staff)),
  onUpdateStaff: staff => dispatch(updateStaff(staff)),
  onDeleteStaff: staff => dispatch(deleteStaff(staff)),

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Staff)))