import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import classnames from "classnames"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import toast, { Toaster } from 'react-hot-toast';


import axios from "axios";
import CardVehicle from "./card-vehicle"
import { withTranslation } from "react-i18next"
import Moment from 'moment';

import { v4 as uuid } from "uuid"
import imageCompression from 'browser-image-compression';


// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"


import maintanence from "../../../assets/images/coming-soon.svg"
import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";

import StarRatings from "react-star-ratings"
import { CardImg, NavLink, NavItem, TabContent, TabPane, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getVehicle,
  addNewVehicle,
  updateVehicle,
  deleteVehicle,
  getReservation,
} from "store/actions"

import Compressor from "compressorjs"
import { vehicleImages } from "assets/images/vehicle"
import exportFromJSON from 'export-from-json'
import { propTypes } from "react-bootstrap-editable";

class Vehicles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //
      modalLog: false,
      vehicleLog: [],
      loadingLog: false,
      filteredVehicles: [],
      user_id: "",
      defaultAvatar: "",
      selectedTariff: null,
      selectedMake: null,
      selectedYear: null,
      selectedModel: null,
      trfchanged: false,
      ibadl: false,
      loading: true,
      submitLoading: false,
      page: 1,
      totalPage: 5,
      activeTab: 1,
      passedSteps: [1],
      isShow: false,
      fuel_changed: false,
      transmission_changed: false,
      status_changed: false,
      features_changed: false,
      gender: "",
      viewmodal: false,
      modal: false,
      isDelete: false,
      isOffline: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      vc: "",
      selectedFeatures: [],
      selectedFuel: "",
      selectedTransmission: "",
      selectedStatus: "",
      vehicles: [],
      reservations: [],
      vehicle: [],
      tariffs: [],
      car_makes: [],
      car_models: [],
      car_years: [],
      reg_file: null,
      other_file: null,
      ex1: null,
      ex2: null,
      ex3: null,
      ex4: null,
      ex5: null,
      in1: null,
      in2: null,
      in3: null,
      in4: null,
      in5: null,
      purchase_date: new Date(),
      inssurance_date: new Date(),
      reg_date: new Date(),
      VehicleColumns: [
        {
          dataField: "plate_number",
          text: this.props.t("Plate Number"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.plate_number}
            </Link>
          ),
        },
        {
          dataField: "createdAt",
          text: this.props.t("Date created"),
          sort: true,
          formatter: (cellContent, row) => (
            <>

              {" "}
              {moment(row.createdAt).format('YYYY-MM-DDTHH:mm')}
            </>
          ),
        },
        {
          dataField: "make",
          text: this.props.t("Make"),
          sort: true,

        },
        {
          dataField: "model",
          text: this.props.t("Model"),
          sort: true,
        },
        /* {
          dataField: "t_name",
          text: this.props.t("Type"),
          sort: true,
        }, */
        {
          dataField: "year",
          text: this.props.t("Year"),
          sort: true,
          /* formatter: (cellContent, row) => (
            this.handleValidDate(row.orderdate)
          ), */
        },

        {
          dataField: "transmission",
          isDummyField: true,
          text: this.props.t("Transmission"),
          sort: true,
          formatter: (cellContent, row) => (
            //kraaaaaaaa
            <>

              <i className={
                row.transmission === "Manual" ?
                  "fas fa-hand-paper me-1"
                  : row.transmission === "Automatic" ? "fas fa-robot" : null
              }
              />{" "}
              {row.transmission}
            </>
          ),
        },
        {
          dataField: "fuel_type",
          text: this.props.t("Fuel Type"),
          sort: true,
        },
        {
          dataField: "status",
          isDummyField: true,
          text: this.props.t("Status"),
          sort: true,
          formatter: (cellContent, row) => (

            <Badge
              className={row.status === 'available' ? "font-size-12 badge-soft-success" : (row.status === "unavailable") ? "font-size-12 badge-soft-warning" : "font-size-12 badge-soft-danger"}
              color={row.status}
              pill
            >
              {this.props.t(row.status)}
            </Badge>
          ),
        },

        {
          dataField: "rented_to",
          text: this.props.t("Rented to"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.rented_to}
            </Link>
          ),
        },
        {
          dataField: "bookedDays",
          text: this.props.t("Rented days"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body ">
              {row.bookedDays}{" "}{this.props.t("Days")}
            </Link>
          ),
        },
        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("View Details"),
          sort: true,
          formatter: (cellContent, vehicle) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded ms-1"
              onClick={() => this.handleLogClick(vehicle)}
            >
              {this.props.t("Clients history")}{" "} <i className="mdi mdi-arrow-right ms-1" />
            </Button>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Action"),
          formatter: (cellContent, vehicle) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-info">
                  <i className="mdi mdi-eye font-size-18" id="edittooltip" onClick={() => this.handleViewClick(vehicle)} />
                </Link>
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleVehicleClick(vehicle)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: vehicle })} />
                </Link>
                {/* {vehicle.offline ?
                  <Link to="#" className="text-danger">
                    <i className="mdi mdi-toggle-switch-off font-size-20" id="switchofftooltip" onClick={() => this.setState({ isOffline: true, vc: vehicle })} />
                  </Link>
                  :
                  <Link to="#" className="text-success">
                    <i className="mdi mdi-toggle-switch font-size-20" id="switchontooltip" onClick={() => this.setState({ isOffline: true, vc: vehicle })} />
                  </Link>
                } */}

              </div>
            </>
          ),
        },
      ],

      activeViewTab: "1",
      selectedFiles: [],
      selectedFiles1: [],
      next: "Next"
    }

    this.handleVehicleClick = this.handleVehicleClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidVehicleSubmit = this.handleValidVehicleSubmit.bind(this)
    this.handleVehicleClicks = this.handleVehicleClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.handleSelectFuel = this.handleSelectFuel.bind(this)
    this.handleSelectTransmission = this.handleSelectTransmission.bind(this)
    this.handleSelectStatus = this.handleSelectStatus.bind(this)
    this.handleSelectFeatures = this.handleSelectFeatures.bind(this)
    this.handleSelectTariff = this.handleSelectTariff.bind(this)
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    this.handleAcceptedFilesDZ1 = this.handleAcceptedFilesDZ1.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)
    this.handleDeleteVehicle = this.handleDeleteVehicle.bind(this)
    this.handleOfflineVehicle = this.handleOfflineVehicle.bind(this)
    this.handleOnlineVehicle = this.handleOnlineVehicle.bind(this)
    this.toggleOffline = this.toggleOffline.bind(this)
    this.toggleViewTab = this.toggleViewTab.bind(this)
    this.toggleLog = this.toggleLog.bind(this)
    this.toggleTab.bind(this)
  }
  handleLogClick = arg => {

    const vehicle = arg

    //console.log("dkhel l log", vehicle.id)
    this.getVehicleLog(vehicle.id)
    this.toggleLog()
  }
  toggleLog() {
    this.setState(prevState => ({
      modalLog: !prevState.modalLog,
    }))
  }
  
  getVehicleLog = async (id) => {
    this.setState({ loadingLog: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeVlog) {
      this.unsubscribeVlog();
    }

    this.unsubscribeVlog = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userVlogCollectionRef = usersCollection.doc(userId).collection("reservations").where("v_id", "==", id).orderBy("pickup_date", "desc");;

            this.unsubscribeVlog = userVlogCollectionRef.onSnapshot(snapshot => {
              let n = [];
              snapshot.docs.forEach(doc => {
                n.push(doc.data());
              });
              ///console.log(n)
              this.setState({
                loadingLog: false,
                vehicleLog: n
              });
            });

            //localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            // localStorage.removeItem("authUser");



          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated

      }
    });

  }
  handlePageClick = page => {
    this.setState({ page })
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        })
      }
    }
  }
  toggleViewTab(tab) {
    if (this.state.activeViewTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeViewTab: tab,
        })
      }
    }
  }

  handleAcceptedFiles = files => {
    this.setState({ ibadl: true })


    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      }),

    )

    this.setState({ selectedFiles: files })
  }
  handleAcceptedFilesDZ1 = files => {
    this.setState({ ibadl: true })
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    )

    this.setState({ selectedFiles1: files })
  }

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }



  toLowerCase1(str) {
    return str.toLowerCase();
  }
  handleSelectTariff = (selectedTariff, value) => {

    this.setState({ selectedTariff: value })
    this.setState({ trfchanged: true })
    //console.log(value)
  }


  handleSelectMake = (selectedMake) => {

    this.setState({ selectedMake: selectedMake })
    //console.log(selectedMake.value)


    const options = {
      method: 'GET',
      url: 'https://cars21.p.rapidapi.com/' + selectedMake.value + '/years',
      headers: {
        'X-RapidAPI-Key': 'd516d672e2msh1bc9800f416b2ebp149439jsn7eae999ec6bc',
        'X-RapidAPI-Host': 'cars21.p.rapidapi.com'
      }
    };
    var self = this
    axios.request(options).then(function (response) {
      //console.log(response.data);
      self.setState({
        car_years: response.data,
      });
    }).catch(function (error) {
      console.error(error);
    });

    /*  axios.get("https://cars-make-model-api.herokuapp.com/" + selectedMake.value + "/years")
       .then(({ data }) => {
         //console.log(data)
         this.setState({
           car_years: data,
         });
       }); */

  }

  handleSelectYear = (selectedYear) => {

    this.setState({ selectedYear: selectedYear })
    //console.log(selectedYear.value)


    const options = {
      method: 'GET',
      url: 'https://cars21.p.rapidapi.com/' + this.state.selectedMake.value + "/" + selectedYear.value,
      headers: {
        'X-RapidAPI-Key': 'd516d672e2msh1bc9800f416b2ebp149439jsn7eae999ec6bc',
        'X-RapidAPI-Host': 'cars21.p.rapidapi.com'
      }
    };
    var self = this
    axios.request(options).then(function (response) {
      //console.log(response.data);
      self.setState({
        car_models: response.data,
      });
    }).catch(function (error) {
      console.error(error);
    });

    /*   axios.get("https://cars-make-model-api.herokuapp.com/" + this.state.selectedMake.value + "/" + selectedYear.value)
        .then(({ data }) => {
          //console.log(data)
          this.setState({
            car_models: data,
          });
        }); */

  }
  handleSelectModel = (selectedModel) => {

    this.setState({ selectedModel: selectedModel })
    //console.log(selectedModel.value)


  }
  handleSelectFeatures = (e) => {

    // let value = Array.from(e.target.selectedOptions, option => option.value);

    const t = new Array()

    e.forEach(element => {
      t.push(element)
    });
    //console.log(e)
    this.setState({ selectedFeatures: t });
  }

  handleSelectFuel = selectedFuel => {

    this.setState({ selectedFuel: selectedFuel })
    this.setState({ fuel_changed: true })
  }
  handleSelectTransmission = selectedTransmission => {

    this.setState({ selectedTransmission: selectedTransmission })
    this.setState({ transmission_changed: true })
  }
  handleSelectStatus = selectedStatus => {

    this.setState({ selectedStatus: selectedStatus })
    this.setState({ status_changed: true })
  }
  componentWillUnmount() {

    this.setState({
      //
      defaultAvatar: null,
      selectedTariff: null,
      selectedMake: null,
      selectedYear: null,
      selectedModel: null,
      trfchanged: null,
      ibadl: null,
      loading: null,
      submitLoading: null,
      page: null,
      totalPage: null,
      activeTab: null,
      passedSteps: null,
      isShow: null,
      fuel_changed: null,
      transmission_changed: null,
      status_changed: null,
      features_changed: null,
      gender: null,
      viewmodal: null,
      modal: null,
      isDelete: null,
      isOffline: null,
      no_data: null,
      success_dlg: null,
      dynamic_title: null,
      dynamic_description: null,
      vc: null,
      selectedFeatures: null,
      selectedFuel: null,
      selectedTransmission: null,
      selectedStatus: null,
      vehicles: null,
      reservations: null,
      vehicle: null,
      tariffs: null,
      car_makes: null,
      car_models: null,
      car_years: null,
      reg_file: null,
      other_file: null,
      ex1: null,
      ex2: null,
      ex3: null,
      ex4: null,
      ex5: null,
      in1: null,
      in2: null,
      in3: null,
      in4: null,
      in5: null,
      purchase_date: null,
      inssurance_date: null,
      reg_date: null,
    });
  }

  componentDidMount() {
    const { vehicles, onGetVehicle } = this.props
    const { onUpdateVehicle } = this.props
    if (vehicles && !vehicles.length) {
      //onGetVehicle()
      this.getVehicle()

    }

    this.setState({ vehicles })
    const { reservations, onGetReservation } = this.state
    if (reservations && !reservations.length) {
      //onGetReservation()
      // this.getReservation()
    }
    this.setState({ reservations })
    //console.log
    const { tariffs } = this.state
    if (tariffs && !tariffs.length) {
      //onGetReservation()
      this.getTariff()
    }
    this.setState({ tariffs })


    /* const options = {
      method: 'GET',
      url: 'https://cars21.p.rapidapi.com/car_makes',
      headers: {
        'X-RapidAPI-Key': 'd516d672e2msh1bc9800f416b2ebp149439jsn7eae999ec6bc',
        'X-RapidAPI-Host': 'cars21.p.rapidapi.com'
      }
    };
    var self=this
    axios.request(options).then(function (response) {
      //console.log(response.data);
      self.setState({
        car_makes: response.data,
      });
    }).catch(function (error) {
      console.error(error);
    }); */

    /* axios.get("https://cars-make-model-api.herokuapp.com/car_makes")
      .then(({ data }) => {

        this.setState({
          car_makes: data,
        });
      }); */


  }
  getTariff = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeTariffs) {
      this.unsubscribeTariffs();
    }

    this.unsubscribeTariffs = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userTariffsCollectionRef = usersCollection.doc(userId).collection("tariffs");

            this.unsubscribeTariffs = userTariffsCollectionRef.onSnapshot(snapshot => {
              let t = [];
              snapshot.docs.forEach(doc => {
                t.push(doc.data());
              });

              this.setState({
                loading: false,
                tariffs: t
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              tariffs: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          tariffs: []
        });
      }
    });

  }
  getVehicle = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeVehicles) {
      this.unsubscribeVehicles();
    }

    this.unsubscribeVehicles = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);

        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userVehiclesCollectionRef = usersCollection.doc(userId).collection("vehicles");
            this.setState({
              user_id: userId,
              account_user: userDoc.data()

            })
            this.unsubscribeVehicles = userVehiclesCollectionRef.onSnapshot(snapshot => {
              let vehicles = [];
              snapshot.docs.forEach(doc => {
                vehicles.push(doc.data());
              });
              const userReservationsCollectionRef = usersCollection.doc(userId).collection("reservations");

              this.unsubscribeReservations = userReservationsCollectionRef.onSnapshot(snapshot => {
                let reservations = [];
                snapshot.docs.forEach(doc => {
                  reservations.push(doc.data());
                });
                vehicles.map(vehicle => {
                  const daysBooked = reservations.reduce((totalDays, reservation) => {
                    if (reservation.v_id === vehicle.id) {
                      return totalDays + reservation.days;
                    }
                    return totalDays;
                  }, 0);

                  vehicle.bookedDays = daysBooked;
                });

                this.setState({
                  loading: false,
                  vehicles: vehicles
                });

              })

            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              vehicles: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          vehicles: []
        });
      }
    });

  }

  getReservation = async () => {
    this.setState({ loading: true });

    // Unsubscribe previous listeners to prevent memory leaks
    if (this.unsubscribeReservations) {
      this.unsubscribeReservations();
    }

    this.unsubscribeReservations = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userId = user.uid;
        const usersCollection = firebase.firestore().collection("users")
        const userDocRef = usersCollection.doc(userId);
        this.setState({
          user_id: userId
        })
        try {
          const userDoc = await userDocRef.get();
          if (userDoc.exists) {
            const userId = userDoc.data().id;
            const userReservationsCollectionRef = usersCollection.doc(userId).collection("reservations");

            this.unsubscribeReservations = userReservationsCollectionRef.onSnapshot(snapshot => {
              let reservations = [];
              snapshot.docs.forEach(doc => {
                reservations.push(doc.data());
              });

              this.setState({
                loading: false,
                reservations: reservations
              });
            });

            localStorage.setItem("authUser", JSON.stringify(user));
          } else {
            // User document not found
            localStorage.removeItem("authUser");
            this.setState({
              loading: false,
              reservations: []
            });
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
          // Handle error
        }
      } else {
        localStorage.removeItem("authUser");
        // Clear the reservations if user is not authenticated
        this.setState({
          loading: false,
          reservations: []
        });
      }
    });
  }


  // eslint-disable-next-line no-unused-vars
  /*  componentDidUpdate(prevProps, prevState, snapshot) {
     const { vehicles, reservations } = this.state
     
     
     
   } */

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      ibadl: false,
      activeTab: 1,
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }
  toggleOffline() {
    this.setState(prevState => ({
      isOffline: !prevState.isOffline,
    }))
  }

  handleVehicleClicks = () => {

    this.setState({
      vehicle: [],

      reg_file: null,
      other_file: null,
      ex1: null,
      ex2: null,
      ex3: null,
      ex4: null,
      ex5: null,
      in1: null,
      in2: null,
      in3: null,
      in4: null,
      in5: null, isEdit: false, isShow: false, selectedTransmission: null, selectedFuel: null, selectedStatus: null
    })

    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  /*  handleTableChange = (type, { page, searchText }) => {
     //console.log("table change ",type)
   } */

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }


  /* Insert,Update Delete data */

  handleOfflineVehicle = (vehicle) => {

    this.vehicleOffline(vehicle)
    this.toggleOffline()
  }
  handleOnlineVehicle = (vehicle) => {

    this.vehicleOnline(vehicle)
    this.toggleOffline()
  }

  handleDeleteVehicle = (vehicle) => {

    this.deleteVehicle(vehicle)
    this.toggleDelete()
  }

  vehicleOnline = (vehicle) => {
    //console.log("online ", vehicle.offline)

    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("vehicles")
    const timeline = {
      statusTitle: "Vehicle updated",
      iconClass: "bx bx-car h2 text-warning",
      description: "Vehicle online",
      data: "",
      time: new Date().getTime(),

    }

    collection.doc(vehicle.id).update({
      offline: false
    }).then(() => {
      //console.log("Document  Updated!");
      toast.success(this.props.t("Document  Updated!"))
      this.addNewTimeline(timeline)
      this.setState({ submitLoading: false })

    }).catch((error) => {
      console.error("Error updating document: ", error);
      toast.error(this.props.t("Error updating document"))
      this.setState({ submitLoading: false })
    });


  }
  vehicleOffline = (vehicle) => {
    //console.log('offline ', vehicle.offline)

    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("vehicles")
    const timeline = {
      statusTitle: "Vehicle updated",
      iconClass: "bx bx-car h2 text-warning",
      description: "Vehicle offline",
      data: "",
      time: new Date().getTime(),

    }

    collection.doc(vehicle.id).update({
      offline: true
    }).then(() => {
      //console.log("Document  Updated!");
      toast.success(this.props.t("Document  Updated!"))
      this.addNewTimeline(timeline)
      this.setState({ submitLoading: false })

    }).catch((error) => {
      console.error("Error updating document: ", error);
      toast.error(this.props.t("Error updating document"))
      this.setState({ submitLoading: false })
    });


  }


  handleViewClick = arg => {
    const vehicle = arg
    ////console.log(vehicle.features)
    this.setState({ selectedFeatures: vehicle.features })
    this.setState({ selectedFuel: { label: vehicle.fuel_type, value: vehicle.fuel_type } })
    this.setState({ selectedTransmission: { label: vehicle.transmission, value: vehicle.transmission } })
    this.setState({ selectedStatus: { label: vehicle.status, value: vehicle.status } })
    //this.setState({ selectedTariff: { label: vehicle.vehicle_type, value: vehicle.trf_id } })
    //console.log(vehicle.ex1_url)
    if (vehicle.ex1_url === "") {
      this.setState({
        defaultAvatar: "https://firebasestorage.googleapis.com/v0/b/meanz-3960c.appspot.com/o/default-avatar.jpg?alt=media&token=fe39669f-b1fc-46b5-89ce-fcea1c8bd4e6"
      })
    } else {
      this.setState({
        defaultAvatar: vehicle.ex1_url
      })
    }
    this.setState({
      vehicle: {
        // code: vehicle.code,
        plate_number: vehicle.plate_number,
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year,
        /* monthly_rate: vehicle.monthly_rate,
        daily_rate: vehicle.daily_rate, 
        t_name: vehicle.t_name,*/
        chassis_number: vehicle.chassis_number,
        fiscal_power: vehicle.fiscal_power,
        mileage: vehicle.mileage,
        rating: vehicle.rating,
        fuel_type: vehicle.fuel_type,
        transmission: vehicle.transmission,
        doors_number: vehicle.doors_number,
        seats_number: vehicle.seats_number,
        features: vehicle.features,
        additional_infos: vehicle.additional_infos,
        notes: vehicle.notes,
        purchase_date: vehicle.purchase_date,
        reg_date: vehicle.reg_date,
        reg_number: vehicle.reg_number,
        warranty_kms: vehicle.warranty_kms,
        warranty_months: vehicle.warranty_months,
        status: vehicle.status,
        available_date: vehicle.available_date,
        start_date: vehicle.start_date,
        offline: vehicle.offline,
        ex1: vehicle.ex1,
        ex2: vehicle.ex2,
        ex3: vehicle.ex3,
        ex4: vehicle.ex4,
        ex5: vehicle.ex5,
        in1: vehicle.in1,
        in2: vehicle.in2,
        in3: vehicle.in3,
        in4: vehicle.in4,
        in5: vehicle.in5,
        ex1_url: vehicle.ex1_url,
        ex2_url: vehicle.ex2_url,
        ex3_url: vehicle.ex3_url,
        ex4_url: vehicle.ex4_url,
        ex5_url: vehicle.ex5_url,
        in1_url: vehicle.in1_url,
        in2_url: vehicle.in2_url,
        in3_url: vehicle.in3_url,
        in4_url: vehicle.in4_url,
        in5_url: vehicle.in5_url,
        rented_to: vehicle.rented_to
        //image: vehicle.image
      },

      isShow: true,
      isEdit: false
    })

    //this.toggleIsShow()
    this.toggle()
    ////console.log(this.state.isShow)
    //this.toggleViewModal()
  }


  handleVehicleClick = arg => {
    const vehicle = arg
    //console.log(vehicle)
    this.setState({ selectedFeatures: vehicle.features })
    this.setState({ selectedFuel: { label: vehicle.fuel_type, value: vehicle.fuel_type } })
    this.setState({ selectedTransmission: { label: vehicle.transmission, value: vehicle.transmission } })
    this.setState({ selectedStatus: { label: vehicle.status, value: vehicle.status } })
    // this.setState({ selectedTariff: { label: vehicle.vehicle_type, value: vehicle.trf_id } })
    this.setState({
      selectedFiles1: [],
      selectedFiles: [],
      vehicle: {
        id: vehicle.id,
        plate_number: vehicle.plate_number,
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year,
        /* monthly_rate: vehicle.monthly_rate,
        daily_rate: vehicle.daily_rate, 
        t_name: vehicle.t_name,*/
        trf_id: vehicle.trf_id,
        chassis_number: vehicle.chassis_number,
        fiscal_power: vehicle.fiscal_power,
        mileage: vehicle.mileage,
        rating: vehicle.rating,
        fuel_type: vehicle.fuel_type,
        transmission: vehicle.transmission,
        doors_number: vehicle.doors_number,
        seats_number: vehicle.seats_number,
        features: vehicle.features,
        additional_infos: vehicle.additional_infos,
        notes: vehicle.notes,
        purchase_date: vehicle.purchase_date,
        reg_date: vehicle.reg_date,
        reg_number: vehicle.reg_number,
        warranty_kms: vehicle.warranty_kms,
        warranty_months: vehicle.warranty_months,
        status: vehicle.status,
        available_date: vehicle.available_date,
        start_date: vehicle.start_date,
        offline: vehicle.offline,
        ex1: vehicle.ex1,
        ex2: vehicle.ex2,
        ex3: vehicle.ex3,
        ex4: vehicle.ex4,
        ex5: vehicle.ex5,
        in1: vehicle.in1,
        in2: vehicle.in2,
        in3: vehicle.in3,
        in4: vehicle.in4,
        in5: vehicle.in5,
        ex1_url: vehicle.ex1_url,
        ex2_url: vehicle.ex2_url,
        ex3_url: vehicle.ex3_url,
        ex4_url: vehicle.ex4_url,
        ex5_url: vehicle.ex5_url,
        in1_url: vehicle.in1_url,
        in2_url: vehicle.in2_url,
        in3_url: vehicle.in3_url,
        in4_url: vehicle.in4_url,
        in5_url: vehicle.in5_url,
        rented_to: vehicle.rented_to
      },

      isEdit: true,
      isShow: false,
    })

    this.toggle()
  }

  /**
   * Handling submit Order on Order form
   */
  handleValidVehicleSubmit = (e, values) => {
    const { onAddNewVehicle, onUpdateVehicle } = this.props
    const { isEdit, vehicles, tariffs, selectedVehicle } = this.state
    /* const cds = []
    this.props.vehicles.map(vcl =>
      cds.push(vcl.code)
    )
    const cd = cds.length == 0 ? 1 : Math.max.apply(null, cds) + 1 */
    ////console.log(values)
    let ftrs = this.state.selectedFeatures.slice()
    // change after 
    /* let img = ""
    if (vehicles.image == null) {
      img = "vehicle13"
    } else {
      img = values.image
    } */
    //console.log(ftrs)


    const foundtrf = tariffs.filter(element => element.id === values.vehicle_type);
    ////console.log(foundtrf[0].monthly_rate)
    var trf_id = values.vehicle_type
    /* var monthly_rate = foundtrf[0].monthly_rate
    var daily_rate = foundtrf[0].daily_rate 
    var t_name = foundtrf[0].tariff_name*/
    ////console.log(t_name)
    /* let ft = this.state

    if (this.state.selectedFuel != null) {
      ft = this.state.selectedFuel.value
      if (this.state.fuel_changed) {
        ft = this.state.selectedFuel.value
        this.setState({ fuel_changed: false })
      } else {
        ft = this.state.selectedFuel.value
      }
    } */
    /* let tr = ""
    if (this.state.selectedTransmission) {
      tr = this.state.selectedTransmission.value
      if (this.state.transmission_changed) {
        tr = this.state.selectedTransmission.value
        this.setState({ transmission_changed: false })
      } else {
        tr = this.state.selectedTransmission.value
      }
    } */
    let st = this.state.vehicle.status
    /* if (this.state.selectedStatus) {
      st = this.state.selectedStatus.value

      if (this.state.status_changed) {
        st = this.state.selectedStatus.value
        this.setState({ status_changed: false })
      } else {
        st = this.state.selectedStatus.value
      }
    } */

    var pn = 0, rn = 0
    var pnA = 0, rnA = 0

    const foundpn = vehicles.filter(element => element.plate_number === values.plate_number.trim() && this.state.vehicle.id !== element.id);
    pn = foundpn.length
    const foundrn = vehicles.filter(element => element.reg_number === values.reg_number.trim() && this.state.vehicle.id !== element.id);
    rn = foundrn.length


    const foundpnA = vehicles.filter(element => element.plate_number === values.plate_number.trim());
    pnA = foundpnA.length
    const foundrnA = vehicles.filter(element => element.reg_number === values.reg_number.trim());
    rnA = foundrnA.length

    /* this.setState({
      gender:this.state.vehicles.gender
    }) */
    let rate = 5.0
    let ad = new Date()
    var av_date = Moment(ad).format('YYYY-MM-DDTHH:mm')
    //console.log(this.state.selectedMake)

    if (isEdit) {
      //des=this.state.selectedGroup.defaultInputValue

      const updateVehicle = {
        id: this.state.vehicle.id,
        plate_number: values.plate_number.trim(),
        make: values.make.trim(),
        model: values.model.trim(),
        year: values.year.trim(),
        /* monthly_rate: monthly_rate,
        daily_rate: daily_rate,
        t_name: t_name,*/
        trf_id: trf_id,
        chassis_number: values.chassis_number.trim(),
        fiscal_power: values.fiscal_power.trim(),
        mileage: values.mileage,
        rating: rate,
        fuel_type: values.fuel_type.trim(),
        transmission: values.transmission.trim(),
        doors_number: values.doors_number.trim(),
        seats_number: values.seats_number.trim(),
        features: ftrs,
        additional_infos: values.additional_infos.trim(),
        notes: values.notes.trim(),
        purchase_date: values.purchase_date.trim(),
        reg_date: values.reg_date.trim(),
        reg_number: values.reg_number.trim(),
        warranty_kms: values.warranty_kms.trim(),
        warranty_months: values.warranty_months.trim(),
        status: st,
        available_date: this.state.vehicle.available_date,
        start_date: this.state.vehicle.start_date,
        rented_to: this.state.vehicle.rented_to
      }

      // update Order

      /*   //console.log("from state ",this.state.vehicle)
        //console.log("from update vehicle",updateVehicle) */
      // onUpdateVehicle(updateVehicle)
      if (this.state.vehicle.plate_number == updateVehicle.plate_number &&
        this.state.vehicle.make == updateVehicle.make &&
        this.state.vehicle.model == updateVehicle.model &&
        this.state.vehicle.year == updateVehicle.year &&/* 
        this.state.vehicle.monthly_rate == updateVehicle.monthly_rate &&
        this.state.vehicle.daily_rate == updateVehicle.daily_rate && 
        this.state.vehicle.t_name == updateVehicle.t_name &&*/
        JSON.stringify(this.state.vehicle.features) == JSON.stringify(ftrs) &&
        this.state.vehicle.chassis_number == updateVehicle.chassis_number &&
        this.state.vehicle.fiscal_power == updateVehicle.fiscal_power &&
        this.state.vehicle.mileage == updateVehicle.mileage &&
        this.state.vehicle.fuel_type == updateVehicle.fuel_type &&
        this.state.vehicle.transmission == updateVehicle.transmission &&
        this.state.vehicle.doors_number == updateVehicle.doors_number &&
        this.state.vehicle.seats_number == updateVehicle.seats_number &&
        this.state.vehicle.warranty_months == updateVehicle.warranty_months &&
        this.state.vehicle.warranty_kms == updateVehicle.warranty_kms &&
        this.state.vehicle.additional_infos == updateVehicle.additional_infos &&
        this.state.vehicle.notes == updateVehicle.notes &&
        this.state.vehicle.purchase_date == updateVehicle.purchase_date &&
        this.state.vehicle.reg_date == updateVehicle.reg_date &&
        this.state.vehicle.reg_number == updateVehicle.reg_number) {
        this.setState({ submitLoading: false })
        this.toggle()

      } else {
        this.updateVehicle(updateVehicle, pn, rn)
      }

    } else {
      //console.log(values)
      const newVehicle = {
        // code: cd,
        plate_number: values["plate_number"],
        make: values["make"] /* this.state.selectedMake.label */,
        model: values["model"]/* this.state.selectedModel.value */,
        year: values["year"]/* this.state.selectedYear.value */,
        /* monthly_rate: monthly_rate,
        daily_rate: daily_rate,
        t_name: t_name,*/
        trf_id: trf_id,
        chassis_number: values["chassis_number"],
        fiscal_power: values["fiscal_power"],
        mileage: values["mileage"],
        /* rating: rate, */
        fuel_type: values["fuel_type"],
        transmission: values["transmission"],
        doors_number: values["doors_number"],
        seats_number: values["seats_number"],
        features: ftrs,
        additional_infos: values["additional_infos"],
        notes: values["notes"],
        purchase_date: values["purchase_date"],
        reg_date: values["reg_date"],
        reg_number: values["reg_number"],
        warranty_kms: values["warranty_kms"],
        warranty_months: values["warranty_months"],
        status: "available",
        available_date: "---",
        start_date: "",
        rented_to: "Not assigned",

      }

      // save new Order

      //onAddNewVehicle(newVehicle)
      this.setState({ vehicle: newVehicle })
      this.addNewVehicle(newVehicle, pnA, rnA)
    }

    this.setState({ selectedVehicle: null })
    //  this.toggle()
  }

  deleteVehicle = (vehicle) => {

    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("vehicles")
    const timeline = {
      statusTitle: "Vehicle deleted",
      iconClass: "bx bx-car h2 text-danger",
      description: "Vehicle information has been deleted for ",
      data: vehicle.make + " " + vehicle.model + " (" + vehicle.plate_number + ") ",
      time: new Date().getTime(),

    }


    collection.doc(vehicle.id).delete().then(() => {




      //console.log("Element  deleted!");
      toast.success(this.props.t("Vehicle deleted "))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Vehicle has been deleted."),
      })

    }).catch((error) => {
      console.error("Error removing element: ", error);
      toast.error(this.props.t("Error removing element"));

    });

  }
  updateVehicle = async (vehicle, pn, rn) => {
    ////console.log(vehicle)
    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("vehicles")
    const timeline = {
      statusTitle: "Vehicle updated",
      iconClass: "bx bx-car h2 text-warning",
      description: "Vehicle information has been updated for ",
      data: vehicle.make + " " + vehicle.model + " (" + vehicle.plate_number + ") ",
      time: new Date().getTime(),

    }



    /*   const options = {
        maxSizeMB: .7,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(f3, options);
        // //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
       // //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
     
      } catch (error) {
        //console.log(error);
      }
       */




    var metadata = {
      contentType: 'image/png',
    };
    if (pn == 0 /* && rn == 0 */) {


      /* //console.log(vehicle.offline) */
      collection.doc(vehicle.id).update({

        plate_number: vehicle.plate_number,
        make: vehicle.make,
        model: vehicle.model,
        year: vehicle.year,
        /* monthly_rate: vehicle.monthly_rate,
        daily_rate: vehicle.daily_rate,
        t_name: vehicle.t_name,*/
        trf_id: vehicle.trf_id,
        chassis_number: vehicle.chassis_number,
        fiscal_power: vehicle.fiscal_power,
        mileage: vehicle.mileage,
        /* rating: vehicle.rating, */
        fuel_type: vehicle.fuel_type,
        transmission: vehicle.transmission,
        doors_number: vehicle.doors_number,
        seats_number: vehicle.seats_number,
        features: vehicle.features,
        additional_infos: vehicle.additional_infos,
        notes: vehicle.notes,
        purchase_date: vehicle.purchase_date,
        reg_date: vehicle.reg_date,
        reg_number: vehicle.reg_number,
        warranty_kms: vehicle.warranty_kms,
        warranty_months: vehicle.warranty_months,
        status: vehicle.status,
        available_date: vehicle.available_date,
        start_date: vehicle.start_date,
        /* offline: vehicle.offline, */

        rented_to: vehicle.rented_to

      }).then(() => {
        //console.log("Document  Updated!");
        toast.success(this.props.t("Document  Updated!"))
        this.addNewTimeline(timeline)
        this.setState({ submitLoading: false })
        this.toggle()
      }).catch((error) => {
        console.error("Error updating document: ", error);
        toast.error(this.props.t("Error updating document"))
        this.setState({ submitLoading: false })
      });




    } else {
      if (pn != 0) {
        console.error("Plate number already exist ");
        toast.error(this.props.t("Plate number already exist"))
        this.setState({ submitLoading: false })
      }
      /* if (rn != 0) {
        console.error("Previous plate number already exist ");
        toast.error(this.props.t("Previous plate  number already exist"))
        this.setState({ submitLoading: false })
      } */

    }

  }

  addNewTimeline = async (timeline) => {
    try {
      const { user_id, account_user } = this.state;
      const collection = firebase.firestore().collection("users").doc(user_id).collection("timeline");
      const newDoc = collection.doc();
      const by = account_user.isAdmin ? 'Admin' : `${account_user.first_name} ${account_user.last_name}`;

      await newDoc.set({
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data: timeline.data,
        user: by
      });

      //console.log("Timeline  written!");
    } catch (error) {
      console.error("Error adding timeline:", error);
    }
  }

  addNewVehicle = async (vehicle, pn, rn) => {
    const collection = firebase.firestore().collection("users").doc(this.state.user_id).collection("vehicles")
    const timeline = {
      statusTitle: "Vehicle added",
      iconClass: "bx bx-car h2 text-success",
      description: "Vehicle information has been added ",
      data: vehicle.make + " " + vehicle.model + " (" + vehicle.plate_number + ") ",
      time: new Date().getTime(),

    }
    this.setState({ submitLoading: true })


    var metadata = {
      contentType: 'image/png',
    };


    var newDoc = collection.doc();
    var doc_id = newDoc.id
    if (pn == 0 /* && rn == 0 */) {




      // var collection.doc(doc_id) = collection.doc();
      newDoc.set(
        {
          id: newDoc.id,
          plate_number: vehicle.plate_number,
          make: vehicle.make,
          model: vehicle.model,
          year: vehicle.year,
          /* monthly_rate: vehicle.monthly_rate,
          daily_rate: vehicle.daily_rate,
          t_name: vehicle.t_name,*/
          trf_id: vehicle.trf_id,
          chassis_number: vehicle.chassis_number,
          fiscal_power: vehicle.fiscal_power,
          mileage: vehicle.mileage,
          /* rating: vehicle.rating, */
          fuel_type: vehicle.fuel_type,
          transmission: vehicle.transmission,
          doors_number: vehicle.doors_number,
          seats_number: vehicle.seats_number,
          features: vehicle.features,
          additional_infos: vehicle.additional_infos,
          notes: vehicle.notes,
          purchase_date: vehicle.purchase_date,
          reg_date: vehicle.reg_date,
          reg_number: vehicle.reg_number,
          warranty_kms: vehicle.warranty_kms,
          warranty_months: vehicle.warranty_months,
          status: vehicle.status,
          available_date: vehicle.available_date,
          start_date: vehicle.start_date,

          rented_to: vehicle.rented_to,
          createdAt: new Date().getTime()

        }).then(() => {
          //console.log("stf  written!");
          toast.success(this.props.t('Vehicle added '))
          this.addNewTimeline(timeline)
          this.setState({ submitLoading: false })
          this.toggle()
        })
        .catch((error) => {
          console.error("Error Adding vehicle: ", error);
          toast.error(this.props.t("Error Adding vehicle"))
          this.setState({ submitLoading: false })
        });





    } else {
      if (pn != 0) {
        console.error("Plate number already exist ");
        toast.error(this.props.t("Plate number already exist"))
        this.setState({ submitLoading: false })
      }
      /*  if (rn != 0) {
         console.error("Previous plate number already exist ");
         toast.error(this.props.t("Previous plate number already exist"))
         this.setState({ submitLoading: false })
       } */

    }


  }




  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }


  changeText = (text) => {

    this.setState({ next: text });
  }

  render() {

    const emptyDataMessage = () => {

      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding your Vehicles informations by clicking ")}<h5 className="text-primary"><Link onClick={this.handleVehicleClicks} >{this.props.t("New Vehicle")}</Link></h5>
                </p>


              </div>
            }

          </Col>
        </Row>
      </Container>


        ;
    }


    const { selectedFuel } = this.state
    const { selectedStatus } = this.state
    const { selectedTransmission } = this.state
    const { vehicles, car_makes, car_years, car_models, reservations, filteredVehicles } = this.state
    ////console.log(car_makes.length)
    //var v=[]
    /* vehicles.map(vehicle => {
      const daysBooked = reservations.reduce((totalDays, reservation) => {
        if (reservation.v_id === vehicle.id) {
          return totalDays + reservation.days;
        }
        return totalDays;
      }, 0);
    
      vehicle.bookedDays = daysBooked;
    });*/
    //v=vehicles
    //console.log(filteredVehicles) 
    const {  vehicleLog, loadingLog } = this.state
    console.log(vehicleLog)
    
    let logData = []
    vehicleLog.map(function (item) {

      logData.push({
        code: item.code,
        client:item.client,
        pickup_date: item.pickup_date,
        pickup_location: item.pickup_location,
        return_date: item.return_date,
        return_location: item.return_location,
        days: item.days,
        total_amount:item.total_amount,
        notes: item.notes,
        status: item.status,
        frais_livraison: item.frais_livraison,
        frais_reprise: item.frais_reprise,
        frais_divers: item.frais_divers,
        caution: item.caution

      })
    });
    const data = vehicles;
    let dataxcl = []
    data.map(function (item) {
      delete item.vehicle;
      dataxcl.push({
        plate_number: item.plate_number,
        make: item.make,
        model: item.model,
        year: item.year,
        chassis_number: item.chassis_number,
        fiscal_power: item.fiscal_power,
        /* rating: item.rating, */
        fuel_type: item.fuel_type,
        transmission: item.transmission,
        doors_number: item.doors_number,
        seats_number: item.seats_number,
        features: item.features,
        additional_infos: item.additional_infos,
        notes: item.notes,
        purchase_date: item.purchase_date,
        reg_date: item.reg_date,
        reg_number: item.reg_number,
        warranty_kms: item.warranty_kms,
        warranty_months: item.warranty_months,
        status: item.status,
      })
    });

    const { selectedGroup } = this.state
    const { SearchBar } = Search
    const { reg_date } = this.state;
    const { purchase_date } = this.state;
    const { isEdit } = this.state
    const { isDelete, isOffline } = this.state
    const { vc } = this.state
    const trfList = []
    const makes = []
    const models = []
    const years = []
    const { tariffs } = this.state

    makes.push({ label: "", value: "" })
    car_makes.map(function (item, i) {
      makes.push({ label: item.name, value: item.value })

    })

    years.push({ label: "", value: "" })
    car_years.map(function (item, i) {

      years.push({ label: item, value: item })

    })

    models.push({ label: "", value: "" })
    car_models.map(function (item, i) {
      models.push({ label: item, value: item })

    })


    trfList.push({ label: "", value: "" })
    tariffs.map(function (item, i) {
      trfList.push({ label: item.tariff_name, value: item.id })

    })

    function exportPDF() {
      const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Vehicles Infos";
      const headers = [["Plate Number", "Make", "Model", "Year", "Chassis N", "Fiscal Power(CV)", "Fuel Type", "Transmission", "Doors", "Purchase date", "Registration Number", "Status"]];

      const dt = data.map(elt => [elt.plate_number, elt.make, elt.model, elt.year, elt.chassis_number, elt.fiscal_power, elt.fuel_type, elt.transmission, elt.doors_number, elt.purchase_date, elt.reg_number, elt.status]);

      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Vehicles.pdf")
    }
    function printPDF() {
      const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 30;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Vehicles Infos";
      const headers = [["Plate Number", "Make", "Model", "Year", "Chassis N", "Fiscal Power(CV)", "Fuel Type", "Transmission", "Doors", "Purchase date", "Registration Number", "Status"]];

      const dt = data.map(elt => [elt.plate_number, elt.make, elt.model, elt.year, elt.chassis_number, elt.fiscal_power, elt.fuel_type, elt.transmission, elt.doors_number, elt.purchase_date, elt.reg_number, elt.status]);
      let content = {
        startY: 100,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }
    const optionTransmission =
      [
        { label: "", value: "" },
        { label: this.props.t("Manual"), value: "Manual" },
        { label: this.props.t("Automatic"), value: "Automatic" },
      ]
    const optionFuel =
      [
        { label: "", value: "" },
        { label: this.props.t("Gasoline"), value: "Gasoline" },
        { label: this.props.t("Diesel"), value: "Diesel" },
        { label: this.props.t("Electric"), value: "Electric" },
        { label: this.props.t("Hybrid"), value: "Hybrid" },
        { label: "GPL", value: "GPL" },
      ]
    const optionFeatures =
      [
        { label: this.props.t("Air conditionner"), value: "Air conditionner" },
        { label: this.props.t("Cruise control"), value: "Cruise control" },
        { label: "GPS", value: "GPS" },
        { label: this.props.t("Baby Seat"), value: "Baby Seat" },
        { label: this.props.t("Bike rack"), value: "Bike rack" },
        { label: this.props.t("Audio/ipod Input"), value: "Audio/ipod Input" },
        { label: this.props.t("Wheel chair accessible"), value: "Wheel chair accessible" },
      ]



    //pagination customization


    const defaultSorted = [{
      dataField: 'createdAt',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };
    function ExportToExcel() {
      exportFromJSON({ data: dataxcl, fileName: 'Vehicles', exportType: exportFromJSON.types.xls })
    }

    function ExportLogToExcel() {
      exportFromJSON({ data: logData, fileName: 'clients history', exportType: exportFromJSON.types.xls })
    }
    /*  var myCurrentDate=new Date();
     var d=new Date(myCurrentDate);
         d.setFullYear(d.getFullYear() - 18);
     var minDate=d.getFullYear()+"-01"
         //console.log(minDate) */
    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Vehicles | Medios - Car Rental Management System")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Fleet")} breadcrumbItem={this.props.t("Vehicles")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory()}
                      keyField='id'
                      columns={(this.state.VehicleColumns || [])}
                      data={(vehicles || [])}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={vehicles}
                          columns={(this.state.VehicleColumns || [])}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2" class="noPrint">

                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    {this.state.activeViewTab === "1" ? <div className="position-relative" >
                                      <SearchBar
                                        {...toolkitProps.searchProps}

                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div> : <div className="position-relative" >

                                    </div>}

                                  </div>
                                </Col>

                                <Col sm="8" >

                                  <div className="text-sm-end" >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleVehicleClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      {this.props.t("New Vehicle")}
                                    </Button>

                                    <Link to="#"

                                    >
                                      {/* <td>
                                        <Nav className="product-view-nav" pills>
                                          <NavItem>
                                            <NavLink
                                              className={classnames({
                                                active: this.state.activeViewTab === "1",
                                              })}
                                              onClick={() => {
                                                this.toggleViewTab("1")
                                              }}
                                            >
                                              <i className="bx bx-list-ul" />
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              className={classnames({
                                                active: this.state.activeViewTab === "2",
                                              })}
                                              onClick={() => {
                                                this.toggleViewTab("2")
                                              }}
                                            >
                                              <i className="bx bx-grid-alt" />
                                            </NavLink>
                                          </NavItem>
                                        </Nav>
                                      </td> */}
                                      {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                      {this.state.activeViewTab === "1" ? <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop" tag="a">
                                            <i className="mdi mdi-dots-vertical font-size-16" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#" onClick={() => this.state.vehicles.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                              <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                              {this.props.t("Export PDF")}

                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.vehicles.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                              <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                              {this.props.t("Export Excel")}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.vehicles.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                              <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                              {this.props.t("Print")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>

                                      </td> : null}

                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              {this.state.activeViewTab === "1" ? <div className="table-responsive">
                                <BootstrapTable

                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}

                                  noDataIndication={emptyDataMessage}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />
                                {this.state.isOffline && vc.offline ? (
                                  <SweetAlert
                                    title={this.props.t("Put vehicle in online mode?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, do it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleOnlineVehicle(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isOffline: false,
                                      })
                                    }
                                  >
                                    {this.props.t("Your vehicle will be available for reservations and will be listed for online bookings!")}
                                  </SweetAlert>
                                ) : null}
                                {this.state.isOffline && !vc.offline ? (
                                  <SweetAlert
                                    title={this.props.t("Put vehicle in offline mode?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, do it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleOfflineVehicle(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isOffline: false,
                                      })
                                    }
                                  >
                                    {this.props.t("Your vehicle will not be available for reservations and will not be listed for online bookings!")}
                                  </SweetAlert>
                                ) : null}

                                {this.state.isDelete ? (
                                  <SweetAlert
                                    title={this.props.t("Are you sure?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, delete it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteVehicle(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isDelete: false,
                                      })
                                    }
                                  >
                                    {this.props.t("You won't be able to revert this!")}
                                  </SweetAlert>
                                ) : null}

                                {this.state.no_data ? (
                                  <SweetAlert
                                    title={this.props.t("No data!")}
                                    warning
                                    onConfirm={() => this.setState({ no_data: false })}
                                  >
                                    {this.props.t("There is no data to export")}
                                  </SweetAlert>
                                ) : null}
                              </div> :
                                <div>
                                  <Row>
                                    {/* Import Cards */}

                                    <CardVehicle vehicles={vehicles} key={"cardvehicle"} />
                                  </Row>

                                  <Row>
                                    {/* <Col lg="12">
                                <Pagination className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                                  <PaginationItem disabled={page === 1}>
                                    <PaginationLink
                                      previous
                                      href="#"
                                      onClick={() => this.handlePageClick(page - 1)}
                                    />
                                  </PaginationItem>
                                  {map(Array(totalPage), (item, i) => (
                                    <PaginationItem active={i + 1 === page} key={'_k'+i}>
                                      <PaginationLink
                                        onClick={() => this.handlePageClick(i + 1)}
                                        href="#"
                                      >
                                        {i + 1}
                                      </PaginationLink>
                                    </PaginationItem>
                                  ))}
                                  <PaginationItem disabled={page === totalPage}>
                                    <PaginationLink
                                      next
                                      href="#"
                                      onClick={() => this.handlePageClick(page + 1)}
                                    />
                                  </PaginationItem>
                                </Pagination>
                              </Col> */}
                                  </Row>
                                </div>
                              }

                              {/* <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div> */}

                              {/* <Modal
                                isOpen={this.state.isOffline}
                                className={this.props.className}
                                size='md'
                              >
                                <ModalHeader toggle={this.toggleOffline} tag="h4">
                                  Select Offline duration
                                </ModalHeader>
                                <ModalBody>

                                  <AvForm
                                    onValidSubmit={
                                      this.handleValidVehicleSubmit
                                    }

                                  >
                                    <Row>
                                      <Col md="6">

                                        <FormGroup className="mb-3">

                                          <Label> {this.props.t("Start date")}</Label>
                                          <AvField
                                            name="pickup_date"
                                            type="datetime-local"
                                            errorMessage={this.props.t("Select a Start date")}
                                            onChange={this.handlePDChange}
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">

                                          <Label> {this.props.t("End date")}</Label>
                                          <AvField
                                            name="return_date"
                                            type="datetime-local"
                                            errorMessage={this.props.t("Select a End date")}
                                            onChange={this.handleRDChange}
                                            validate={{
                                              required: { value: true },
                                            }}
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <div className="text-end">

                                          <button
                                            type="submit"
                                            className="btn btn-success save-user"
                                          >
                                            {this.props.t("Confirm")}
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </AvForm>
                                </ModalBody>
                              </Modal>
 */}
 
                              <Modal
                                isOpen={this.state.modal}
                                className={this.props.className}
                                size='lg'
                              >
                                <ModalHeader toggle={this.toggle} tag="h4">
                                  {!!isEdit ? this.props.t("Edit Vehicle ") : null}
                                  {!!this.state.isShow ? this.props.t("Vehicle Infos") : null}
                                  {!isEdit && !this.state.isShow ? this.props.t("Add Vehicle") : null}
                                </ModalHeader>
                                <ModalBody>
                                  {this.state.submitLoading ?


                                    <Col>
                                      <div>
                                        <div >
                                          <div className="spinner-chase">
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                            <div className="chase-dot"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                      <h6 className='text-center'>{this.props.t("Uploading data ...")}</h6>
                                    </Col>
                                    :
                                    <AvForm
                                      onValidSubmit={
                                        this.handleValidVehicleSubmit
                                      }

                                    >
                                      {this.state.isShow ?
                                        <Row>
                                          <Col>
                                            <Card>
                                              <CardBody>

                                                <Row>
                                                  {/*  <Col xl="7" >


                                                    <Col>

                                                       <CardImg
                                                        src={this.state.defaultAvatar}
                                                        alt={this.state.vehicles.make + " " + this.state.vehicles.model + " " + this.state.vehicles.year}

                                                        style={{ borderRadius: 8, maxHeight: 400, maxWidth: 400 }}
                                                        className="img-fluid"
                                                      /> 

                                                    </Col>

                                                  </Col>*/}

                                                  <Col md="12">
                                                    <Link to="#" className="text-primary">
                                                      {this.state.vehicle.plate_number}
                                                    </Link>
                                                    <h4 className="mt-1 mb-3">{this.state.vehicle.make + " " + this.state.vehicle.model + " " + this.state.vehicle.year}</h4>

                                                    {/* <div className="text-muted float-start me-3">
                                                      <StarRatings
                                                        rating={this.state.vehicle.rating}
                                                        starRatedColor="#F1B44C"
                                                        starEmptyColor="#2D363F"
                                                        numberOfStars={5}
                                                        name="rating"
                                                        starDimension="14px"
                                                        starSpacing="3px"
                                                      />
                                                    </div> */}
                                                    <p className="text-muted mb-4">
                                                      {/* {product.reviews} */} {this.props.t("")}
                                                    </p>


                                                    <h5 className="mb-2">
                                                      {this.props.t("Description")} {" "}
                                                      <span className="text-muted me-2">
                                                        <del>{/* ${product.oldPrice} */} </del>
                                                      </span>{" "}
                                                      <b>{/* ${product.newPrice} */} </b>
                                                    </h5>
                                                    <p className="text-muted mb-2">
                                                      {this.state.vehicle.additional_infos}
                                                    </p>
                                                    <h6 >{this.props.t("Notes")}
                                                      <p className="text-muted ">
                                                        {this.state.vehicle.notes}
                                                      </p>
                                                    </h6>

                                                    <Row className="mb-3">
                                                      <Col md="12">

                                                        {this.state.vehicle.features.length !== 0 ? <h5 className="mb-3">{this.props.t("Features")}</h5> : null}
                                                        {this.state.vehicle.features &&
                                                          this.state.vehicle.features.map((item, i) => (

                                                            <div key={i}>


                                                              <i
                                                                className=" fa fa-caret-right font-size-16 align-middle text-primary me-2"

                                                              />
                                                              {item.type && `${item.type}: `}
                                                              {item.value}

                                                            </div>
                                                          ))}
                                                      </Col>

                                                    </Row>


                                                  </Col>
                                                </Row>

                                                <div className="mt-3">
                                                  <h5 className="mb-3">{this.props.t("Specifications")}</h5>

                                                  <div className="table-responsive">
                                                    <Table className="table mb-0 table-bordered">
                                                      <tbody>
                                                        <tr >
                                                          <th
                                                            scope="row"
                                                            style={{ width: "400px" }}
                                                            className={"text-capitalize"}
                                                          >
                                                            {this.props.t("Plate number")}
                                                          </th>
                                                          <td>{this.state.vehicle.plate_number}</td>
                                                        </tr>
                                                        <tr >
                                                          <th
                                                            scope="row"
                                                            style={{ width: "400px" }}
                                                            className={"text-capitalize"}
                                                          >
                                                            {this.props.t("Fuel type")}
                                                          </th>
                                                          <td>{this.state.vehicle.fuel_type}</td>
                                                        </tr>

                                                        <tr >
                                                          <th
                                                            scope="row"
                                                            style={{ width: "400px" }}
                                                            className={"text-capitalize"}
                                                          >
                                                            {this.props.t("Transmission")}
                                                          </th>
                                                          <td>{this.state.vehicle.transmission}</td>
                                                        </tr>

                                                        <tr >
                                                          <th
                                                            scope="row"
                                                            style={{ width: "400px" }}
                                                            className={"text-capitalize"}
                                                          >
                                                            {this.props.t("Fiscal Power")}
                                                          </th>
                                                          <td>{this.state.vehicle.fiscal_power}</td>
                                                        </tr>

                                                        <tr >
                                                          <th
                                                            scope="row"
                                                            style={{ width: "400px" }}
                                                            className={"text-capitalize"}
                                                          >
                                                            {this.props.t("Mileage")}
                                                          </th>
                                                          <td>{this.state.vehicle.mileage}</td>
                                                        </tr>
                                                        <tr >
                                                          <th
                                                            scope="row"
                                                            style={{ width: "400px" }}
                                                            className={"text-capitalize"}
                                                          >
                                                            {this.props.t("Status")}
                                                          </th>
                                                          <td>{this.state.vehicle.status}</td>
                                                        </tr>

                                                        <tr >
                                                          <th
                                                            scope="row"
                                                            style={{ width: "400px" }}
                                                            className={"text-capitalize"}
                                                          >
                                                            {this.props.t("Purchase date")}
                                                          </th>
                                                          <td>{this.state.vehicle.purchase_date}</td>
                                                        </tr>

                                                        <tr >
                                                          <th
                                                            scope="row"
                                                            style={{ width: "400px" }}
                                                            className={"text-capitalize"}
                                                          >
                                                            {this.props.t("Registration date")}
                                                          </th>
                                                          <td>{this.state.vehicle.reg_date}</td>
                                                        </tr>

                                                        <tr >
                                                          <th
                                                            scope="row"
                                                            style={{ width: "400px" }}
                                                            className={"text-capitalize"}
                                                          >
                                                            {this.props.t("Registration number")}
                                                          </th>
                                                          <td>{this.state.vehicle.reg_number}</td>
                                                        </tr>




                                                      </tbody>
                                                    </Table>
                                                  </div>
                                                </div>
                                                {/* <Reviews comments={product.comments} /> */}
                                              </CardBody>
                                            </Card>
                                          </Col>
                                        </Row>

                                        :
                                        <div className="wizard clearfix">
                                          <div className="steps clearfix">
                                            <ul>
                                              <NavItem
                                                className={classnames({
                                                  current: this.state.activeTab === 1,
                                                })}>
                                                <NavLink
                                                  className={classnames({
                                                    active: this.state.activeTab === 1,
                                                  })}
                                                  onClick={() => {
                                                    this.toggleTab(1)
                                                  }}
                                                >
                                                  <span className="number">1</span>{this.props.t("General Infos")}
                                                </NavLink>
                                              </NavItem>
                                              <NavItem
                                                className={classnames({
                                                  current: this.state.activeTab === 2,
                                                })}>
                                                <NavLink
                                                  disabled={!(this.state.passedSteps || []).includes(2)}
                                                  className={classnames({
                                                    active: this.state.activeTab === 2,
                                                  })}
                                                  onClick={() => {
                                                    this.toggleTab(2)
                                                  }}
                                                >
                                                  <span className="number">2</span>{this.props.t("Features")}
                                                </NavLink>
                                              </NavItem>
                                              <NavItem
                                                className={classnames({
                                                  current: this.state.activeTab === 3,
                                                })}>
                                                <NavLink
                                                  disabled={!(this.state.passedSteps || []).includes(3)}
                                                  className={classnames({
                                                    active: this.state.activeTab === 3,
                                                  })/* , 'done' */}
                                                  onClick={() => {
                                                    this.toggleTab(3)
                                                  }}
                                                >
                                                  <span className="number">3.</span>{this.props.t("Documents")}
                                                </NavLink>
                                              </NavItem>
                                              {/* <NavItem
                                                className={classnames({
                                                  current: this.state.activeTab === 4,
                                                })}>
                                                <NavLink
                                                  disabled={!(this.state.passedSteps || []).includes(4)}
                                                  className={classnames({
                                                    active: this.state.activeTab === 4,
                                                  })}
                                                  onClick={() => {
                                                    this.toggleTab(4)
                                                  }}
                                                >
                                                  <span className="number">4.</span>{this.props.t("Images")}
                                                </NavLink>
                                              </NavItem> */}
                                            </ul>
                                          </div>
                                          <div className="content clearfix">
                                            <TabContent
                                              activeTab={this.state.activeTab}
                                              className="body"
                                            >
                                              <TabPane tabId={1}>
                                                <Form>
                                                  <Row>
                                                    <Col lg="4">
                                                      <FormGroup className="mb-3">
                                                        <Label >
                                                          {this.props.t("Plate Number")}
                                                        </Label>
                                                        <AvField
                                                          name="plate_number"
                                                          placeholder={this.props.t("Plate Number")}
                                                          type="text"
                                                          disabled={isEdit ? true : false}
                                                          errorMessage={this.props.t("Enter Plate Number")}
                                                          className="form-control"
                                                          validate={{ required: { value: true } }}
                                                          value={this.state.vehicle.plate_number || ""}

                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                      <FormGroup className="mb-3">
                                                        <Label >
                                                          {this.props.t("Chassis Number")}
                                                        </Label>
                                                        <AvField
                                                          name="chassis_number"
                                                          placeholder={this.props.t("Chassis Number")}
                                                          type="text"
                                                          value={this.state.vehicle.chassis_number || ""}

                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                      <FormGroup className="mb-3">
                                                        <div className="mb-3 select2-container">
                                                          <Label>{this.props.t("Rate")}</Label>
                                                          <AvField
                                                            name="vehicle_type"
                                                            type="select"
                                                            //placeholder={this.state.reservations.vehicle_type}
                                                            onChange={this.handleSelectTariff}
                                                            // options={trfList}
                                                            value={this.state.vehicle.trf_id}
                                                            disabled={this.state.isShow ? true : false}
                                                            errorMessage={this.props.t("Select a Rate")}
                                                            className="form-control"

                                                          /* validate={{
                                                            required: { value: true },
                                                          }} */
                                                          >

                                                            {trfList.map((e, key) => {
                                                              if (key == 0) {
                                                                return <option key={key} value="" disabled>{this.props.t("Rate")}</option>;
                                                              } else {
                                                                return <option key={key} value={e.value}>{e.label}</option>;
                                                              }

                                                            })}
                                                          </AvField>
                                                          <Link to="/tariffs" target="_blank" rel="noopener noreferrer">{this.props.t("Add new Rate")}</Link>
                                                        </div>

                                                      </FormGroup>
                                                    </Col>
                                                  </Row>

                                                  <Row>
                                                    <Col lg="4">


                                                      {/* <Label>{this.props.t("Make")}</Label>


                                                      <Select
                                                      name='make'
                                                        defaultValue={{ label: this.state.vehicle.make, value: this.state.vehicle.make }}
                                                        onChange={this.handleSelectMake}
                                                        options={makes}
                                                        placeholder="Select Car Make."
                                                        isDisabled={this.state.vehicle.status==="rented" ? true : false}
                                                        errorMessage={this.props.t("Select a Make")}
                                                      />

                                                       <AvField
                                                            name="make"
                                                            type="select"
                                                            //placeholder={this.state.reservations.vehicle_type}
                                                            onChange={this.handleSelectMake}
                                                            // options={trfList}
                                                            defaultValue={this.state.vehicle.make}
                                                            disabled={this.state.isShow ? true : false}
                                                            errorMessage={this.props.t("Select a Make")}
                                                            className="form-control"

                                                            validate={{
                                                              required: { value: true },
                                                            }}
                                                          >

                                                            {makes.map((e, key) => {
                                                              if (key == 0) {
                                                                return <option key={key} value="" disabled>Make</option>;
                                                              } else {
                                                                return <option key={key} value={e.value}>{e.name}</option>;
                                                              }

                                                            })}
                                                          </AvField> */}



                                                      <FormGroup className="mb-3">
                                                        <Label >
                                                          {this.props.t("Make")}
                                                        </Label>
                                                        <AvField
                                                          name="make"
                                                          placeholder={this.props.t("Vehicle Make")}
                                                          type="text"
                                                          errorMessage={this.props.t("Enter Vehicle make")}
                                                          className="form-control"
                                                          validate={{ required: { value: true } }}
                                                          value={this.state.vehicle.make || ""}

                                                        />
                                                      </FormGroup>
                                                    </Col>

                                                    <Col lg="4">
                                                      {/* <Label>{this.props.t("Year")}</Label>


                                                      <Select
                                                      name="year"
                                                        defaultValue={{ label: this.state.vehicle.year, value: this.state.vehicle.year }}
                                                        onChange={this.handleSelectYear}
                                                        options={years}
                                                        isDisabled={this.state.vehicle.status==="rented" ? true : false}
                                                        errorMessage={this.props.t("Select a year")}
                                                        placeholder="Select Year."
                                                      /> */}
                                                      {/* <FormGroup className="mb-3">
                                                        <div className="mb-3 select2-container">
                                                          <Label>{this.props.t("Year")}</Label>
                                                          <AvField
                                                            name="year"
                                                            type="select"
                                                            //placeholder={this.state.reservations.vehicle_type}
                                                            onChange={this.handleSelectYear}
                                                            // options={trfList}
                                                            defaultValue={this.state.vehicle.year}
                                                            disabled={this.state.isShow ? true : false}
                                                            errorMessage={this.props.t("Select a year")}
                                                            className="form-control"

                                                            validate={{
                                                              required: { value: true },
                                                            }}
                                                          >

                                                            {years.map((e, key) => {
                                                              if (key == 0) {
                                                                return <option key={key} value="" disabled>Year</option>;
                                                              } else {
                                                                return <option key={key} value={e}>{e}</option>;
                                                              }

                                                            })}
                                                          </AvField>

                                                        </div>

                                                      </FormGroup> */}

                                                      <FormGroup className="mb-3">
                                                        <Label >
                                                          {this.props.t("Year")}
                                                        </Label>
                                                        <AvField
                                                          name="year"
                                                          placeholder={this.props.t("Year")}
                                                          type="number"
                                                          errorMessage={this.props.t("Enter Year")}
                                                          validate={{ required: { value: true } }}
                                                          value={this.state.vehicle.year || ""}

                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                      {/*  <Label>{this.props.t("Model")}</Label>


                                                      <Select
                                                      name="model"
                                                        defaultValue={{ label: this.state.vehicle.model, value: this.state.vehicle.model }}
                                                        onChange={this.handleSelectModel}
                                                        options={models}
                                                        isDisabled={this.state.vehicle.status==="rented" ? true : false}
                                                        errorMessage={this.props.t("Select a model")}
                                                        placeholder="Select Car Model."
                                                      />
                                                       <FormGroup className="mb-3">
                                                        <div className="mb-3 select2-container">
                                                          <Label>{this.props.t("Model")}</Label>
                                                          <AvField
                                                            name="model"
                                                            type="select"
                                                            //placeholder={this.state.reservations.vehicle_type}
                                                            //onChange={this.handleSelectYear}
                                                            // options={trfList}
                                                            defaultValue={this.state.vehicle.model}
                                                            disabled={this.state.isShow ? true : false}
                                                            errorMessage={this.props.t("Select a model")}
                                                            className="form-control"

                                                            validate={{
                                                              required: { value: true },
                                                            }}
                                                          >

                                                            {models.map((e, key) => {
                                                              if (key == 0) {
                                                                return <option key={key} value="" disabled>Model</option>;
                                                              } else {
                                                                return <option key={key} value={e}>{e}</option>;
                                                              }

                                                            })}
                                                          </AvField>

                                                        </div>

                                                      </FormGroup> */}
                                                      <FormGroup className="mb-3">
                                                        <Label >
                                                          {this.props.t("Model")}
                                                        </Label>
                                                        <AvField
                                                          name="model"
                                                          placeholder={this.props.t("Vehicle Model")}
                                                          type="text"
                                                          errorMessage={this.props.t("Enter Vehicle model")}
                                                          className="form-control"
                                                          validate={{ required: { value: true } }}
                                                          value={this.state.vehicle.model || ""}

                                                        />
                                                      </FormGroup>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col lg="6">
                                                      <FormGroup className="mb-3">
                                                        <Label >
                                                          {this.props.t("Fiscal Power")} (cv)
                                                        </Label>
                                                        <AvField
                                                          name="fiscal_power"
                                                          placeholder={this.props.t("Fiscal Power")}
                                                          type="select"
                                                          errorMessage={this.props.t("Enter Fiscal Power")}
                                                          value={this.state.vehicle.fiscal_power || ""}
                                                          validate={{ required: { value: true } }}
                                                        >
                                                          <option value="" disabled >{this.props.t("Fiscal power")}</option>
                                                          <option value="1 - 7 cv">1 - 7 cv</option>
                                                          <option value="8- 10 cv">8- 10 cv</option>
                                                          <option value="11 - 14 cv">11 - 14 cv</option>
                                                          <option value="+15 cv">+15 cv</option>
                                                        </AvField>
                                                      </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                      <FormGroup className="mb-3">
                                                        <Label >
                                                          {this.props.t("Mileage")}
                                                        </Label>
                                                        <AvField
                                                          name="mileage"
                                                          placeholder={this.props.t("Mileage")}
                                                          type="number"
                                                          errorMessage={this.props.t("Enter Mileage")}
                                                          value={this.state.vehicle.mileage || ""}
                                                        // validate={{ required: { value: true } }}
                                                        />
                                                        {/*  <option value="" disabled >mileage</option>
                                                          <option value="0 - 15,000 km">0 - 15,000 km</option>
                                                          <option value="15- 50,000 km">15- 50,000 km</option>
                                                          <option value="50 - 100,000 km">50 - 100,000 km</option>
                                                          <option value="100 - 150,000 km">100 - 150,000 km</option>
                                                          <option value="150 - 200,000 km">150 - 200,000 km</option>
                                                          <option value="+200,000 km">+200,000 km</option>
                                                        </AvField> */}
                                                      </FormGroup>
                                                    </Col>
                                                  </Row>
                                                </Form>
                                              </TabPane>
                                              <TabPane tabId={2}>
                                                <div>
                                                  <Form>
                                                    <Row>
                                                      <Col lg="6">
                                                        <FormGroup className="mb-3">
                                                          <div className="mb-3 select2-container">
                                                            <Label>{this.props.t("Fuel type")}</Label>
                                                            <AvField
                                                              name="fuel_type"
                                                              type="select"
                                                              //placeholder={this.state.vehicles.fuel_type}
                                                              //options={optionFuel}
                                                              isDisabled={this.state.isShow ? true : false}
                                                              //onChange={this.handleSelectFuel}
                                                              value={this.state.vehicle.fuel_type}
                                                              errorMessage={this.props.t("Select Fuel type")}
                                                              className="form-control"

                                                              validate={{
                                                                required: { value: true },
                                                              }}
                                                            >
                                                              {optionFuel.map((e, key) => {
                                                                if (key == 0) {
                                                                  return <option key={key} value="" disabled>{this.props.t("Fuel type")}</option>;
                                                                } else {
                                                                  return <option key={key} value={e.value}>{e.label}</option>;
                                                                }
                                                              })}
                                                            </AvField>
                                                          </div>
                                                        </FormGroup>
                                                      </Col>

                                                      <Col lg="6">
                                                        <FormGroup className="mb-3">
                                                          <div className="mb-3 select2-container">
                                                            <Label>{this.props.t("Transmission")}</Label>
                                                            <AvField
                                                              name="transmission"
                                                              type="select"
                                                              //placeholder={this.state.vehicles.transmission}
                                                              //options={optionTransmission}
                                                              isDisabled={this.state.isShow ? true : false}
                                                              //onChange={this.handleSelectTransmission}
                                                              value={this.state.vehicle.transmission}
                                                              errorMessage={this.props.t("Select Transmission")}
                                                              className="form-control"

                                                              validate={{
                                                                required: { value: true },
                                                              }}
                                                            >
                                                              {optionTransmission.map((e, key) => {
                                                                if (key == 0) {
                                                                  return <option key={key} value="" disabled>{this.props.t("transmission")}</option>;
                                                                } else {
                                                                  return <option key={key} value={e.value}>{e.label}</option>;
                                                                }
                                                              })}
                                                            </AvField>
                                                          </div>
                                                        </FormGroup>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col lg="6">
                                                        <FormGroup className="mb-3">
                                                          <Label >
                                                            {this.props.t("Number of Doors")}
                                                          </Label>
                                                          <AvField
                                                            name="doors_number"
                                                            placeholder={this.props.t("Number of Doors")}
                                                            type="select"
                                                            errorMessage={this.props.t("Enter number of doors")}
                                                            value={this.state.vehicle.doors_number || ""}

                                                          >
                                                            <option value="" disabled >{this.props.t("number of doors")}</option>
                                                            <option >2</option>
                                                            <option >3</option>
                                                            <option >4</option>
                                                            <option >5</option>
                                                            <option >6</option>
                                                          </AvField>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col lg="6">
                                                        <FormGroup className="mb-3">
                                                          <Label >
                                                            {this.props.t("Number of Seats")}
                                                          </Label>
                                                          <AvField
                                                            name="seats_number"
                                                            placeholder={this.props.t("Number of Seats")}
                                                            type="select"
                                                            errorMessage={this.props.t("Enter number of seats")}
                                                            value={this.state.vehicle.seats_number || ""}

                                                          >
                                                            <option value="" disabled >{this.props.t("number of seats")}</option>
                                                            <option >2</option>
                                                            <option >3</option>
                                                            <option >4</option>
                                                            <option >5</option>
                                                            <option >6</option>
                                                            <option >7</option>
                                                            <option >8</option>
                                                          </AvField>
                                                        </FormGroup>
                                                      </Col>

                                                    </Row>
                                                    <Row>
                                                      <Col lg="6">
                                                        <FormGroup className="mb-3">
                                                          <div className="mb-3 select2-container">
                                                            <Label>{this.props.t("Features")}</Label>
                                                            <Select
                                                              isMulti
                                                              name="features"

                                                              defaultValue={this.state.vehicle.features}
                                                              options={optionFeatures}
                                                              isDisabled={this.state.isShow ? true : false}
                                                              onChange={this.handleSelectFeatures}

                                                            />
                                                          </div>
                                                        </FormGroup>
                                                      </Col>
                                                      <Col lg="6">
                                                        <FormGroup className="mb-3">
                                                          <AvField
                                                            name="additional_infos"
                                                            label={this.props.t("Additional information")}
                                                            placeholder={this.props.t("Additional infos")}
                                                            type="text"
                                                            value={this.state.vehicle.additional_infos || ""}
                                                          />
                                                        </FormGroup>
                                                      </Col>
                                                    </Row>

                                                  </Form>
                                                </div>
                                              </TabPane>
                                              <TabPane tabId={3}>
                                                <div>
                                                  <Form>
                                                    <Row>
                                                      <Col lg="4">
                                                        <FormGroup className="mb-3">
                                                          <Label> {this.props.t("First release")}</Label>
                                                          <AvField
                                                            name="purchase_date"
                                                            placeholder={this.props.t("First release")}
                                                            type="date"
                                                            validate={{
                                                              required: { value: true },
                                                            }}
                                                            value={Moment(this.state.vehicle.purchase_date).format('YYYY-MM-DD') || ""}
                                                            disabled={this.state.isShow ? true : false}
                                                          />
                                                        </FormGroup>
                                                      </Col>

                                                      {/* <Col lg="6">
                                                      <FormGroup className="mb-3">
                                                        <div className="mb-3 select2-container">
                                                          <Label>{this.props.t("Status")}</Label>
                                                          <Select
                                                            name="status"
                                                            placeholder={this.state.vehicles.status}
                                                            options={optionStatus}
                                                            isDisabled={this.state.isShow ? true : false}
                                                            onChange={this.handleSelectStatus}
                                                            value={selectedStatus}
                                                          />
                                                        </div>
                                                      </FormGroup>
                                                    </Col> */}

                                                      <Col lg="4">
                                                        <FormGroup className="mb-3">
                                                          <Label> {this.props.t("Validity start")}</Label>
                                                          <AvField
                                                            name="reg_date"
                                                            placeholder={this.props.t("Validity start")}
                                                            type="date"
                                                            validate={{
                                                              required: { value: true },
                                                            }}
                                                            value={Moment(this.state.vehicle.reg_date).format('YYYY-MM-DD') || ""}
                                                            disabled={this.state.isShow ? true : false}
                                                          />

                                                        </FormGroup>
                                                      </Col>
                                                      <Col lg="4">
                                                        <FormGroup className="mb-3">
                                                          <Label >
                                                            {this.props.t("Previous plate number")}
                                                          </Label>
                                                          <AvField
                                                            name="reg_number"
                                                            placeholder={this.props.t("WWW...")}
                                                            type="text"

                                                            value={this.state.vehicle.reg_number || ""}

                                                          />
                                                        </FormGroup>

                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col lg="6">
                                                        <FormGroup className="mb-3">
                                                          <AvField
                                                            name="warranty_months"
                                                            label={this.props.t("Warranty in months")}
                                                            placeholder={this.props.t("Warranty in months")}
                                                            type="number"
                                                            value={this.state.vehicle.warranty_months || ""}
                                                          />
                                                        </FormGroup>
                                                      </Col>
                                                      <Col lg="6">
                                                        <FormGroup className="mb-3">
                                                          <AvField
                                                            name="warranty_kms"
                                                            label={this.props.t("Warranty in KMs")}
                                                            placeholder={this.props.t("Warranty in KMs")}
                                                            type="number"
                                                            value={this.state.vehicle.warranty_kms || ""}
                                                          />
                                                        </FormGroup>
                                                      </Col>
                                                    </Row>
                                                    <Row>
                                                      <Col lg="12">
                                                        <FormGroup className="mb-3">
                                                          <AvField
                                                            name="notes"
                                                            label={this.props.t("Notes")}
                                                            placeholder={this.props.t("Notes")}
                                                            type="textarea"
                                                            rows="1"
                                                            value={this.state.vehicle.notes || ""}
                                                          />
                                                        </FormGroup>
                                                      </Col>
                                                    </Row>


                                                  </Form>
                                                </div>
                                              </TabPane>
                                              <TabPane tabId={4}>
                                                <Row>
                                                  <Col lg="6">
                                                    <Form>
                                                      <Label>{this.props.t("Exterior images")} (Max size : 1Mb)</Label>
                                                      <Dropzone
                                                        onDrop={acceptedFiles =>
                                                          this.handleAcceptedFiles(acceptedFiles)
                                                        }

                                                        accept={'image/jpeg, image/png'}
                                                        maxSize={1000000}
                                                        maxFiles={5}

                                                      >
                                                        {({ getRootProps, getInputProps }) => (
                                                          <div className="dropzone">
                                                            <div
                                                              className="dz-message needsclick"
                                                              {...getRootProps()}
                                                            >
                                                              <input {...getInputProps()} />
                                                              <div className="mb-3">
                                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                              </div>
                                                              <h4>{this.props.t("Drop files here or click to upload.")}</h4>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </Dropzone>
                                                      <div
                                                        className="dropzone-previews mt-3"
                                                        id="file-previews"
                                                      >
                                                        {this.state.selectedFiles.slice(0, 5).map((f, i) => {
                                                          return (
                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={f.name}
                                                                      src={f.preview}
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted font-weight-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>{f.formattedSize}</strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          )
                                                        })}
                                                      </div>
                                                    </Form>


                                                  </Col>
                                                  <Col lg="6">
                                                    <Form>
                                                      <Label>{this.props.t("Interior images")} (Max size : 1Mb)</Label>
                                                      <Dropzone
                                                        onDrop={acceptedFiles =>
                                                          this.handleAcceptedFilesDZ1(acceptedFiles)
                                                        }

                                                        accept={'image/jpeg, image/png'}
                                                        maxSize={1000000}
                                                        maxFiles={5}
                                                      >
                                                        {({ getRootProps, getInputProps }) => (
                                                          <div className="dropzone">
                                                            <div
                                                              className="dz-message needsclick"
                                                              {...getRootProps()}
                                                            >
                                                              <input {...getInputProps()} />
                                                              <div className="mb-3">
                                                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                              </div>
                                                              <h4>{this.props.t("Drop files here or click to upload.")}</h4>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </Dropzone>
                                                      <div
                                                        className="dropzone-previews mt-3"
                                                        id="file-previews"
                                                      >
                                                        {this.state.selectedFiles1.slice(0, 5).map((f, i) => {

                                                          return (

                                                            <Card
                                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                              key={i + "-file"}
                                                            >
                                                              <div className="p-2">
                                                                <Row className="align-items-center">
                                                                  <Col className="col-auto">
                                                                    <img
                                                                      data-dz-thumbnail=""
                                                                      height="80"
                                                                      className="avatar-sm rounded bg-light"
                                                                      alt={f.name}
                                                                      src={f.preview}
                                                                    />
                                                                  </Col>
                                                                  <Col>
                                                                    <Link
                                                                      to="#"
                                                                      className="text-muted font-weight-bold"
                                                                    >
                                                                      {f.name}
                                                                    </Link>
                                                                    <p className="mb-0">
                                                                      <strong>{f.formattedSize}</strong>
                                                                    </p>
                                                                  </Col>
                                                                </Row>
                                                              </div>
                                                            </Card>
                                                          )
                                                        })}
                                                      </div>
                                                    </Form>


                                                  </Col>
                                                </Row>
                                              </TabPane>
                                            </TabContent>
                                          </div>
                                          <div className="actions clearfix">
                                            <ul>
                                              <li
                                                className={
                                                  this.state.activeTab === 1
                                                    ? "previous disabled"
                                                    : "previous"
                                                }
                                              >
                                                <Link
                                                  to="#"
                                                  onClick={() => {
                                                    this.toggleTab(this.state.activeTab - 1)
                                                  }}
                                                >
                                                  {this.props.t("Previous")}
                                                </Link>
                                              </li>
                                              <li
                                                className={
                                                  this.state.activeTab === 3
                                                    ? "next"
                                                    : "next disable"
                                                }
                                              >
                                                {this.state.activeTab === 3 && !this.state.isShow ? <button className="btn btn-success save-user" >
                                                  {this.props.t("Save")}
                                                </button> : <Link
                                                  to="#"
                                                  onClick={() => {
                                                    this.toggleTab(this.state.activeTab + 1)
                                                  }}
                                                  className="next disable"
                                                >
                                                  {this.state.activeTab === 4 ? this.props.t("Save") : this.props.t("Next")}
                                                </Link>}


                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      }

                                    </AvForm>
                                  }
                                </ModalBody>
                              </Modal>
                              <Modal
                                  isOpen={this.state.modalLog}
                                  className={this.props.className}
                                  size="lg"
                                >
                              <ModalHeader toggle={this.toggleLog} tag="h4">
                                    {this.props.t("Clients history")}
                                  </ModalHeader>
                                  <ModalBody>
                                    {loadingLog ?
                                      <div className="text-center">
                                        <Row className="justify-content-center mt-5">
                                          <Col sm="4">
                                            <div>
                                              <div >
                                                <div className="spinner-chase">
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                  <div className="chase-dot"></div>
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div> :
                                      (vehicleLog.length !== 0 ?
                                        <div>
                                          <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                            <Table className="table mb-0">
                                              <thead>
                                                <tr>
                                                  <th>{this.props.t("Client")}</th>
                                                  <th>{this.props.t("Pickup date")}</th>
                                                  <th>{this.props.t("Return date")}</th>
                                                  <th>{this.props.t("Days")}</th>
                                                  <th>{this.props.t("Total amount")}</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {vehicleLog.map((log, index) => (
                                                  <tr key={index}>
                                                    <th scope="row">{log.client}</th>
                                                    <td>{log.pickup_date}</td>
                                                    <td>{log.return_date}</td>
                                                    <td>{log.days}</td>
                                                    <td>{log.total_amount}{" "}{this.props.t("MAD")}</td>
                                                    
                                                  </tr>
                                                ))}

                                              </tbody>
                                            </Table>
                                          </div>

                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user mt-3"
                                                  onClick={() => ExportLogToExcel()}
                                                >
                                                  <i className="mdi mdi-microsoft-excel font-size-16  me-1" />{" "}{this.props.t("Export")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                        : <div className="text-center mt-4"><div className="mb-4">
                                          <i className="bx bx-history text-primary display-4" style={{ lineHeight: '1' }} />

                                        </div><h4>{this.props.t("No bookings yet")}</h4>
                                          <p className="mb-4">{this.props.t("Vehicle clients history is displayed here.")}</p>


                                        </div>)
                                    }
                                  </ModalBody>
                                </Modal>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
      </React.Fragment>
    )
  }
}

Vehicles.propTypes = {

  reservations: PropTypes.array,
  onGetReservation: PropTypes.func,
  vehicles: PropTypes.array,
  onGetVehicle: PropTypes.func,
  onAddNewVehicle: PropTypes.func,
  onDeleteVehicle: PropTypes.func,
  onUpdateVehicle: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  vehicles: state.vehicle.vehicles,
  reservations: state.reservation.reservations,
})

const mapDispatchToProps = dispatch => ({
  onGetVehicle: () => dispatch(getVehicle()),
  onGetReservation: () => dispatch(getReservation()),
  onAddNewVehicle: vehicle => dispatch(addNewVehicle(vehicle)),
  onUpdateVehicle: vehicle => dispatch(updateVehicle(vehicle)),
  onDeleteVehicle: vehicle => dispatch(deleteVehicle(vehicle)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Vehicles)))
