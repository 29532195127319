import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom"
import { countBy, isEmpty, size, times } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import toastr from "toastr";
import { withTranslation } from "react-i18next"
import maintanence from "../../../assets/images/coming-soon.svg"

// Add the Firebase products that you want to use
import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"

import
paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import * as moment from 'moment';
import Select from "react-select"
import jsPDF from "jspdf";
import "jspdf-autotable";
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, InputGroup, Input, FormGroup, Label, Button, Card, CardBody, Col, Table, ModalFooter, Container, Row, Modal, Badge, ModalHeader, ModalBody } from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"
import SweetAlert from "react-bootstrap-sweetalert"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  getCompany,
  addNewCompany,
  updateCompany,
  deleteCompany,
  getIndie
} from "store/actions"


import exportFromJSON from 'export-from-json'
import { isThisExpression } from "@babel/types";
import { companies } from "common/data";

class Companies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      submitLoading: false,
      isShow: false,
      changed: false,
      gender: "",
      viewmodal: false,
      modal: false,
      isDelete: false,
      no_data: false,
      success_dlg: false,
      dynamic_title: '',
      dynamic_description: '',
      vc: "",
      selectedGroup: null,
      companies: [],
      indies: [],
      company: [],
      date_birth: new Date(),
      CompanyColumns: [

        {
          dataField: "company_name",
          text: this.props.t("Name"),
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.company_name}
            </Link>
          ),
        },
        {
          dataField: "email",
          text: this.props.t("E-mail"),
          sort: true,
        },

        {
          dataField: "website",
          text: this.props.t("Website"),
          sort: true,
        },
        {
          dataField: "phone_1",
          text: this.props.t("Phone number"),
          sort: true,
        },

        {
          dataField: "address",
          text: this.props.t("Address"),
          sort: true,
        },

        {
          dataField: "view",
          isDummyField: true,
          text: this.props.t("View Details"),
          sort: true,
          formatter: (cellContent, company) => (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => this.handleViewClick(company)}
            >
              {this.props.t("View Details")}
            </Button>
          ),
        },
        {
          dataField: "action",
          isDummyField: true,
          text: this.props.t("Action"),
          formatter: (cellContent, company) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleCompanyClick(company)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({ isDelete: true, vc: company })} />
                </Link>
              </div>



              {/* <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" onClick={() => this.handleCompanyClick(company)} />
                </Link>
                <Link to="#" className="text-danger">
                  <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={() => this.setState({isDelete:true,vc:company})} />
                </Link>
              </div> */}
            </>
          ),
        },
      ],
      selectedFiles: []
    }

    this.handleCompanyClick = this.handleCompanyClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleValidCompanySubmit = this.handleValidCompanySubmit.bind(this)
    this.handleCompanyClicks = this.handleCompanyClicks.bind(this)
    this.toLowerCase1 = this.toLowerCase1.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleViewClick = this.handleViewClick.bind(this)
    this.toggleIsShow = this.toggleIsShow.bind(this)
  }





  toLowerCase1(str) {
    return str.toLowerCase();
  }

  componentDidMount() {
    const { companies, onGetCompany, indies } = this.state
    if (companies && !companies.length) {
      //onGetCompany()
      this.getCompany()
    }
    this.setState({ companies })
    if (indies && !indies.length) {
      //onGetCompany()
      this.getIndie()
    }
    this.setState({ indies })

  }
  getIndie = async () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {

        user = firebase.auth().currentUser.uid
        ////console.log("dkhel " + user)
        const collection = firebase.firestore().collection("users")


        this.setState({ loading: true }, () => {
          collection.doc(user).collection("indies")
            .onSnapshot((doc) => {
              let kra = []
              doc.docs.forEach(e => {
                kra.push(e.data())
              });
              this.setState({
                loading: false,
                indies: kra,
              })
            }

            );
        });
        localStorage.setItem("authUser", JSON.stringify(user))
      } else {
        ////console.log("not user")
        localStorage.removeItem("authUser")
      }
    })

  }
  getCompany = async () => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {

        user = firebase.auth().currentUser.uid

        const collection = firebase.firestore().collection("users")


        this.setState({ loading: true }, () => {
          collection.doc(user).collection("clients")
            .onSnapshot((doc) => {
              let kra = []
              doc.docs.forEach(e => {
                kra.push(e.data())
              });
              this.setState({
                loading: false,
                companies: kra,
              })
            }

            );
        });
        localStorage.setItem("authUser", JSON.stringify(user))
      } else {
        //      //console.log("not user")
        localStorage.removeItem("authUser")
      }
    })

  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { companies } = this.state

    /*  if (!isEmpty(companies) && size(prevProps.companies) !== size(companies)) {
       this.setState({ companies: {}, isEdit: false })
     }
 
     const { indies } = this.props
 
     if (!isEmpty(indies) && size(prevProps.indies) !== size(indies)) {
       this.setState({ indies: {}, isEdit: false })
     } */
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  toggleDelete() {
    this.setState(prevState => ({
      isDelete: !prevState.isDelete,
    }))
  }

  handleCompanyClicks = () => {
    this.setState({ company: [], isEdit: false, isShow: false })

    this.toggle()
  }

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (type, { page, searchText }) => {
    const { companies } = this.props
    this.setState({
      companies: companies.filter(company =>
        Object.keys(company).some(
          key =>
            typeof company[key] === "string" &&
            company[key].toLowerCase().includes(searchText.toLowerCase())
        )
      ),
    })
  }

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }))
  }

  toggleIsShow = () => {
    this.setState(prevState => ({
      isShow: !prevState.isShow,
    }))
  }


  /* Insert,Update Delete data */

  handleDeleteCompany = (company) => {


    const { onDeleteCompany } = this.state
    /*  if (company['code'] === '') {
       //
     } else { */
    //onDeleteCompany(company)
    this.deleteCompany(company)
    this.toggleDelete()
    //}

  }

  handleViewClick = arg => {
    const company = arg

    this.setState({
      company: {
        id: company.id,
        code: company.code,
        company_name: company.company_name,
        email: company.email,
        fax: company.fax,
        website: company.website,
        phone_1: company.phone_1,
        phone_2: company.phone_2,
        address: company.address,
        city: company.city,
        state: company.state,
        country:company.country,
        notes: company.notes,
        rc:company.rc,
        patente:company.patente,
        id_fiscal:company.id_fiscal
      },

      isShow: true,
      isEdit: false

    })

    //this.toggleIsShow()
    this.toggle()

    //this.toggleViewModal()
  }


  handleCompanyClick = arg => {
    const company = arg

    //this.setState({selectedGroup:staff.designation})
    this.setState({
      company: {
        id: company.id,
        code: company.code,
        company_name: company.company_name,
        email: company.email,
        fax: company.fax,
        website: company.website,
        phone_1: company.phone_1,
        phone_2: company.phone_2,
        address: company.address,
        city: company.city,
        state: company.state,
        country:company.country,
        notes: company.notes,
        rc:company.rc,
        patente:company.patente,
        id_fiscal:company.id_fiscal
      },
      isEdit: true,
      isShow: false,
    })

    this.toggle()
  }

  /**
   * Handling submit Order on Order form
   */
  handleValidCompanySubmit = (e, values) => {
    const { onAddNewCompany, onUpdateCompany } = this.props
    const { isEdit, companies, selectedCompany } = this.state

    const cds = []
    this.state.indies.map(stf =>
      cds.push(stf.code)
    )
    this.state.companies.map(stf =>
      cds.push(stf.code)
    )
    const cd = cds.length == 0 ? 1 : Math.max.apply(null, cds) + 1
    /*  let des= this.state.selectedGroup.value
     if(this.state.changed){
       des= this.state.selectedGroup.value
       this.setState({changed:false})
     }else{
       des=this.state.selectedGroup
     }
      */

    /* this.setState({
      gender:this.state.staffs.gender
    }) */

    var em = 0, na = 0
    var emA = 0, naA = 0

    const foundem = companies.filter(element => element.email === values.email.trim() && this.state.company.id !== element.id);
    em = foundem.length
    const foundna = companies.filter(element => element.company_name === values.company_name.trim() && this.state.company.id !== element.id);
    na = foundna.length


    const foundemA = companies.filter(element => element.email === values.email.trim());
    emA = foundemA.length
    const foundnaA = companies.filter(element => element.company_name === values.company_name.trim());
    naA = foundnaA.length


    if (isEdit) {
      //des=this.state.selectedGroup.defaultInputValue

      const updateCompany = {

        id: this.state.company.id,
        company_name: values.company_name.trim(),
        email: values.email.trim(),
        fax: values.fax.trim(),
        website: values.website.trim(),
        phone_1: values.phone_1.trim(),
        phone_2: values.phone_2.trim(),
        address: values.address.trim(),
        city: values.city.trim(),
        state: values.state.trim(),
        country: values.country.trim(),
        notes: values.notes.trim(),
        rc: values.rc.trim(),
        patente: values.patente.trim(),
        id_fiscal: values.id_fiscal.trim()

      }

      // update Order
      //onUpdateCompany(updateCompany)
      if (this.state.company.company_name == updateCompany.company_name
        && this.state.company.email == updateCompany.email
        && this.state.company.fax == updateCompany.fax
        && this.state.company.website == updateCompany.website
        && this.state.company.phone_1 == updateCompany.phone_1
        && this.state.company.phone_2 == updateCompany.phone_2
        && this.state.company.address == updateCompany.address
        && this.state.company.city == updateCompany.city
        && this.state.company.state == updateCompany.state
        && this.state.company.notes == updateCompany.notes
        && this.state.company.website == updateCompany.website
        && this.state.company.rc == updateCompany.rc
        && this.state.company.patente == updateCompany.patente
        && this.state.company.id_fiscal == updateCompany.id_fiscal) {
        this.setState({ submitLoading: false })
        this.toggle()
      } else {
        this.updateCompany(updateCompany, em, na)
      }

    } else {

      const newCompany = {
        code: cd,
        company_name: values['company_name'],
        email: values['email'],
        fax: values['fax'],
        website: values['website'],
        phone_1: values['phone_1'],
        phone_2: values['phone_2'],
        address: values['address'],
        city: values['city'],
        state: values['state'],
        country: values['country'],
        notes: values['notes'],
        rc: values['rc'],
        patente: values['patente'],
        id_fiscal: values['id_fiscal'],
      }
      ////console.log(des)
      // save new Order
      // onAddNewCompany(newCompany)
      this.setState({ company: newCompany })
      this.addNewCompany(newCompany, emA, naA)
    }
    //this.setState({ selectedCompany: null })
    //this.toggle()
  }
  addNewTimeline = async (timeline) => {
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("timeline")
    var newDoc = collection.doc();
    newDoc.set(
      {
        id: newDoc.id,
        statusTitle: timeline.statusTitle,
        iconClass: timeline.iconClass,
        description: timeline.description,
        time: timeline.time,
        data:timeline.data,

      }).then(() => {
        //console.log("timeline  written!");
       })
      .catch((error) => {
        console.error("Error Adding timeline: ", error);
      });

  }

  deleteCompany = (company) => {
    ////console.log(supplier)
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("clients")
    const timeline = {
      statusTitle: "Company deleted",
      iconClass: "bx bx-user h2 text-danger",
      description: "Company information has been deleted for ",
      data:company.company_name,
      time: new Date().getTime(),

    }
    collection.doc(company.id).delete().then(() => {

      //console.log("Element  deleted!");
      toastr.success(this.props.t("Company infos Deleted "))
      this.addNewTimeline(timeline)
      this.setState({
        success_dlg: true,
        dynamic_title: this.props.t("Deleted"),
        dynamic_description: this.props.t("Company has been deleted."),
      })
    }).catch((error) => {
      console.error("Error removing element: ", error);
      toastr.error("Error removing element: ", error);

    });

  }

  updateCompany = (company, em, na) => {

    this.setState({ submitLoading: true })
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("clients")

    const timeline = {
      statusTitle: "Company updated",
      iconClass: "bx bx-user h2 text-warning",
      description: "Company information has been updated for ",
      data:company.company_name,
      time: new Date().getTime(),

    }
    if (em == 0 && na == 0) {
      collection.doc(company.id).update({
        company_name: company.company_name,
        email: company.email,
        fax: company.fax,
        website: company.website,
        phone_1: company.phone_1,
        phone_2: company.phone_2,
        address: company.address,
        city: company.city,
        state: company.state,
        country:company.country,
        notes: company.notes,
        rc: company.rc,
        patente: company.patente,
        id_fiscal: company.id_fiscal

      }).then(() => {
        //console.log("Document  Updated!");
        toastr.success("Document  Updated!")
        this.addNewTimeline(timeline)
        this.setState({ submitLoading: false })
        this.toggle()
      }).catch((error) => {
        console.error("Error updating document: ", error);
        toastr.error("Error updating document")
        this.setState({ submitLoading: false })
      });
    } else {
      if (em != 0) {
        console.error("Email already exist ");
        toastr.error("Email already exist ")
        this.setState({ submitLoading: false })
      }
      if (na != 0) {
        console.error("client already exist ");
        toastr.error("Client already exist ")
        this.setState({ submitLoading: false })
      }
    }
  }

  addNewCompany = (company, em, na) => {
    const collection = firebase.firestore().collection("users").doc(firebase.auth().currentUser.uid).collection("clients")

    this.setState({ submitLoading: true })
    const timeline = {
      statusTitle: "Company added",
      iconClass: "bx bx-user h2 text-success",
      description: "Company information has been added ",
      data:company.company_name,
      time: new Date().getTime(),

    }
    if (em == 0 && na == 0) {

      var newDoc = collection.doc();
      newDoc.set(
        {
          id: newDoc.id,
          code: company.code,
          company_name: company.company_name,
          email: company.email,
          fax: company.fax,
          website: company.website,
          phone_1: company.phone_1,
          phone_2: company.phone_2,
          address: company.address,
          city: company.city,
          state: company.state,
          country:company.country,
          notes: company.notes,
          rc: company.rc,
          patente: company.patente,
          id_fiscal: company.id_fiscal

        }).then(() => {
          //console.log("spl  written!");
          toastr.success('Client added  ')
          this.addNewTimeline(timeline)
          this.setState({ submitLoading: false })
          this.toggle()
        })
        .catch((error) => {
          console.error("Error Adding Client: ", error);
          toastr.error("Error Adding Client")
          this.setState({ submitLoading: false })
        });

    } else {
      if (em != 0) {
        console.error("Email already exist ");
        toastr.error("Email already exist ")
        this.setState({ submitLoading: false })
      }
      if (na != 0) {
        console.error("Client already exist ");
        toastr.error("Client already exist ")
        this.setState({ submitLoading: false })
      }
    }
  }











  handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  }




  render() {
    const emptyDataMessage = () => {

      return <Container>
        <Row>
          <Col lg="12">
            {this.state.loading ?
              <div className="text-center">
                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div>
                      <div >
                        <div className="spinner-chase">
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                          <div className="chase-dot"></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              :
              <div className="text-center">

                <Row className="justify-content-center mt-5">
                  <Col sm="4">
                    <div className="maintenance-img">
                      <img
                        src={maintanence}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
                <h4 className="mt-5">{this.props.t("Let's get started with Medios")}</h4>
                <p className="text-muted">
                  {this.props.t("Start adding your Clients informations by clicking ")}<h5 className="text-primary"><Link onClick={this.handleCompanyClicks} >{this.props.t("New Client")}</Link></h5>
                </p>


              </div>
            }

          </Col>
        </Row>
      </Container>


        ;
    }


    const { companies } = this.state
    const data = companies;
    let dataxcl = []
    data.map(function (item) {
      delete item.company;
      dataxcl.push({
        company_name: item.company_name,
        email: item.email,
        fax: item.fax,
        website: item.website,
        phone_1: item.phone_1,
        phone_2: item.phone_2,
        address: item.address,
        city: item.city,
        state: item.state,
        country:item.country,
        notes: item.notes,
        rc:item.rc,
        patente:item.patente,
        id_fiscal:item.id_fiscal
      })
    });
    const { selectedGroup } = this.state
    const { SearchBar } = Search
    const { isEdit } = this.state
    const { isDelete } = this.state
    const { vc } = this.state

    //pagination customization


    const defaultSorted = [{
      dataField: 'orderId',
      order: 'desc'
    }];

    const selectRow = {
      mode: 'checkbox',
    };
    function ExportToExcel() {
      exportFromJSON({ data: dataxcl, fileName: 'Companies', exportType: exportFromJSON.types.xls })
    }
    function exportPDF() {
      const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Companies Infos";
      const headers = [["Company Name", "Email", "Fax", "Website", "Phone 1", "Phone 2", "Address"]];

      const dt = data.map(elt => [elt.company_name, elt.email, elt.fax, elt.website, elt.phone_1, elt.phone_2, elt.address + " \n" + elt.city]);

      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      doc.save("Companies.pdf")
    }
    function printPDF() {
      const unit = "pt";
      const size = "A3"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 20;
      const doc = new jsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "Companies Infos";
      const headers = [["Company Name", "Email", "Fax", "Website", "Phone 1", "Phone 2", "Address"]];

      const dt = data.map(elt => [elt.company_name, elt.email, elt.fax, elt.website, elt.phone_1, elt.phone_2, elt.address + " \n" + elt.city]);


      let content = {
        startY: 50,
        head: headers,
        body: dt
      };

      doc.text(title, marginLeft, 20);
      doc.autoTable(content);
      var string = doc.output('datauristring');
      var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      var x = window.open();
      x.document.open();
      x.document.write(embed);
      x.document.close();

    }

    return (

      <React.Fragment>


        <div className="page-content">
          <MetaTags>
            <title>Clients | Medios - Car Rental Management System</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Clients")} breadcrumbItem={this.props.t("Companies")} />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory()}
                      keyField='id'
                      columns={(this.state.CompanyColumns || [])}
                      data={(companies || [])}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={companies}
                          columns={(this.state.CompanyColumns || [])}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2" class="noPrint">
                                <Col sm="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="8" >

                                  <div className="text-sm-end" >
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.handleCompanyClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />{" "}
                                      {this.props.t("New Client")}
                                    </Button>

                                    <Link to="#"

                                    >

                                      {/*  <i className="mdi mdi-file-export-outline font-size-18" /> */}
                                      <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop" tag="a">
                                            <i className="mdi mdi-dots-vertical font-size-18" />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem href="#" onClick={() => this.state.companies.length == 0 ? this.setState({ no_data: true }) : exportPDF()}>
                                              <i className="mdi mdi-file-pdf-box font-size-16 text-danger me-1" />{" "}
                                              {this.props.t("Export PDF")}

                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.companies.length == 0 ? this.setState({ no_data: true }) : ExportToExcel()} >

                                              <i className="mdi mdi-microsoft-excel font-size-16 text-success me-1" />{" "}
                                              {this.props.t("Export Excel")}
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => this.state.companies.length == 0 ? this.setState({ no_data: true }) : printPDF()}>
                                              <i className="mdi mdi-printer font-size-16 text-grey me-1" />{" "}
                                              {this.props.t("Print")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>

                                      </td>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable

                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  noDataIndication={emptyDataMessage}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                />
                                <Modal
                                  isOpen={this.state.modal}
                                  className={this.props.className}
                                >
                                  <ModalHeader toggle={this.toggle} tag="h4">
                                    {!!isEdit ? this.props.t("Edit Client") : null}
                                    {!!this.state.isShow ? this.props.t("Company Infos") : null}
                                    {!isEdit && !this.state.isShow ? this.props.t("Add Client") : null}
                                  </ModalHeader>
                                  <ModalBody>
                                    {this.state.submitLoading ?

                                      <Col>
                                        <div>
                                          <div >
                                            <div className="spinner-chase">
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                              <div className="chase-dot"></div>
                                            </div>
                                          </div>
                                        </div>
                                        <br />
                                        <h6 className='text-center'>uploading data ...</h6>
                                      </Col> :
                                      <AvForm
                                        onValidSubmit={
                                          this.handleValidCompanySubmit
                                        }
                                        disabled={this.state.isShow ? true : false}
                                      >
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom01">
                                                {this.props.t("Company name")}
                                              </Label>
                                              <AvField
                                                name="company_name"
                                                placeholder={this.props.t("Company name")}
                                                type="text"
                                                errorMessage={this.props.t("Enter Company Name")}
                                                className="form-control"
                                                validate={{ required: { value: true } }}
                                                id="validationCustom01"
                                                value={this.state.company.company_name || ""}

                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>
                                        <Row>
                                          <Col md="6">

                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom03">
                                                {this.props.t("E-mail")}
                                              </Label>
                                              <AvField
                                                name="email"
                                                placeholder={this.props.t("E-mail")}
                                                type="text"
                                                errorMessage={this.props.t("Enter a valid E-mail")}
                                                className="form-control"
                                                validate={{
                                                  required: { value: true },
                                                  email: { value: true },
                                                }}
                                                id="validationCustom03"
                                                value={this.state.company.email || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom04">
                                                Fax
                                              </Label>
                                              <AvField
                                                name="fax"
                                                placeholder="Fax"
                                                type="text"
                                                id="validationCustom04"
                                                value={this.state.company.fax || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <Label >
                                                {this.props.t("Website")}
                                              </Label>
                                              <AvField
                                                name="website"
                                                placeholder={this.props.t("Website")}
                                                type="text"
                                                value={this.state.company.website || ""}
                                              />
                                            </FormGroup>
                                          </Col>


                                        </Row>
                                        <Row>
                                          <Col md="6">
                                            <FormGroup className="mb-3">
                                              <Label htmlFor="validationCustom04">
                                                {this.props.t("Phone number")}
                                              </Label>
                                              <AvField
                                                name="phone_1"
                                                placeholder={this.props.t("Phone number")}
                                                type="text"
                                                errorMessage={this.props.t("Enter a valid phone number")}
                                                className="form-control"

                                                validate={{
                                                  required: { value: true },
                                                }}
                                                id="validationCustom04"
                                                value={this.state.company.phone_1 || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="6 ">
                                            <FormGroup className="mb-3">
                                              <Label >
                                                {this.props.t("Phone number 2")}
                                              </Label>
                                              <AvField
                                                name="phone_2"
                                                placeholder={this.props.t("Phone number 2")}
                                                type="text"
                                                value={this.state.company.phone_2 || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>

                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="address"
                                                label={this.props.t("Address")}
                                                type="textarea"
                                                errorMessage={this.props.t("Invalid Address")}
                                                rows="1"
                                                placeholder={this.props.t("Address")}
                                                value={this.state.company.address || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>

                                        <Row>
                                          <Col md="4">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="city"
                                                label={this.props.t("City")}
                                                type="text"
                                                errorMessage={this.props.t("Invalid City")}
                                                placeholder={this.props.t("City")}
                                                value={this.state.company.city || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="4">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="state"
                                                label={this.props.t("Region")}
                                                type="text"
                                                /* errorMessage={this.props.t("Invalid State")} */
                                                placeholder={this.props.t("region")}
                                                value={this.state.company.state || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="4">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="country"
                                                label={this.props.t("Country")}
                                                type="text"
                                                errorMessage={this.props.t("Invalid Country")}
                                                placeholder={this.props.t("Country")}
                                                value={this.state.company.country || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        
                                        <Row>
                                          <Col md="4">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="rc"
                                                label={this.props.t("R C")}
                                                type="text"
                                                placeholder={this.props.t("Trade register number")}
                                                value={this.state.company.rc || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="4">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="License"
                                                label={this.props.t("License")}
                                                type="text"
                                                placeholder={this.props.t("License number")}
                                                value={this.state.company.patente || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md="4">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="id_fiscal"
                                                label={this.props.t("Tax identification number")}
                                                type="text"
                                                placeholder={this.props.t("Tax identification number")}
                                                value={this.state.company.id_fiscal || ""}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md="12">
                                            <FormGroup className="mb-3">
                                              <AvField
                                                name="notes"
                                                label={this.props.t("Notes")}
                                                type="textarea"
                                                placeholder={this.props.t("Notes")}
                                                rows="1"
                                                value={this.state.company.notes || ""}
                                              />
                                            </FormGroup>
                                          </Col>

                                        </Row>


                                        {this.state.isShow ? null :
                                          <Row>
                                            <Col>
                                              <div className="text-end">

                                                <button
                                                  type="submit"
                                                  className="btn btn-success save-user"
                                                >
                                                  {this.props.t("Save")}
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        }

                                      </AvForm>
                                    }
                                  </ModalBody>
                                </Modal>


                                {this.state.isDelete ? (
                                  <SweetAlert
                                    title={this.props.t("Are you sure?")}
                                    warning
                                    showCancel
                                    confirmButtonText={this.props.t("Yes, delete it!")}
                                    confirmBtnBsStyle="success"
                                    cancelBtnBsStyle="danger"
                                    onConfirm={() =>
                                      this.handleDeleteCompany(vc)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        isDelete: false,
                                      })
                                    }
                                  >
                                    {this.props.t("You won't be able to revert this!")}
                                  </SweetAlert>
                                ) : null}
                                {this.state.no_data ? (
                                  <SweetAlert
                                    title={this.props.t("No data!")}
                                    warning
                                    onConfirm={() => this.setState({ no_data: false })}
                                  >
                                    {this.props.t("There is no data to export")}
                                  </SweetAlert>
                                ) : null}

                              </div>

                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Companies.propTypes = {
  companies: PropTypes.array,
  onGetCompany: PropTypes.func,
  onGetIndie: PropTypes.func,
  onAddNewCompany: PropTypes.func,
  onDeleteCompany: PropTypes.func,
  onUpdateCompany: PropTypes.func,
  className: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = state => ({
  companies: state.clients.companies
})

const mapDispatchToProps = dispatch => ({
  onGetCompany: () => dispatch(getCompany()),
  onGetIndie: () => dispatch(getIndie()),
  onAddNewCompany: company => dispatch(addNewCompany(company)),
  onUpdateCompany: company => dispatch(updateCompany(company)),
  onDeleteCompany: company => dispatch(deleteCompany(company)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Companies)))
